import { useState, useEffect } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { useDetectiveContext } from '../../../context/DetectiveContext';
import api from '../../../../../services/api';
import { CLIENT_ID } from '../../../../../services/auth';
import { PersonHistory } from '../../../../monitoring/types/Monitoring';
import { initialHistory } from '../constants/history';

export const useHistory = () => {
  const { selectedPerson, updateSelectedPerson, initHistory, setInitHistory, setSearchListActive } =
    useDetectiveContext();
  const [personHistory, setPersonHistory] = useState<PersonHistory>(initialHistory);
  const [periodHistory, setPeriodHistory] = useState<number>(5);
  const [loading, setLoading] = useState<boolean>(false);
  const clientId: string | null = localStorage.getItem(CLIENT_ID)
    ? localStorage.getItem(CLIENT_ID)
    : '2';

  const { collaborator, currentLocation, historic } = personHistory;
  const { lat, lng } = personHistory.currentLocation;

  useEffect(() => {
    if (selectedPerson === 0) return;

    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted: boolean = true;
    setLoading(true);

    api
      .get('/monitoring/collaborator', {
        params: {
          collaboratorId: selectedPerson,
          period: periodHistory,
          clientId,
        },
        cancelToken: currentRequest.token,
      })
      .then((res: AxiosResponse<any>) => {
        const { data } = res;

        if (!mounted) return;

        setPersonHistory(data);
        setInitHistory(true);
        setSearchListActive(false);
      })
      .catch((error: AxiosResponse<any>) => error)
      .finally(() => {
        if (!mounted) return;
        setLoading(false);
      });

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [periodHistory, selectedPerson]);

  useEffect(() => {
    if (!initHistory) setPeriodHistory(5);
  }, [initHistory]);

  const handleClickToClose = () => {
    setPersonHistory(initialHistory);
    updateSelectedPerson(0);
  };

  return {
    lng,
    lat,
    historic,
    initHistory,
    collaborator,
    loading,
    currentLocation,
    handleClickToClose,
    setPeriodHistory,
  };
};
