import {
  IAuthRequest,
  signIn as signInService,
  getRefreshToken as getRefreshTokenService,
  IAuthResponse,
} from '../services/api/auth';
import CookieService from '../services/CookieService';
import { AuthData } from '../types';

const ACCESS_TOKEN_KEY = '@trackfy_access_token';
const REFRESH_ACCESS_TOKEN_KEY = '@trackfy_refresh_access_token';
const USER_ID = '@trackfy_user_id';
const CLIENT_ID = '@trackfy_client_id';
const USER_NAME = '@trackfy_user_name';
const COMPANY_ID = '@trackfy_company_id';

export const useAuth = () => {
  const isAuthenticated = () => localStorage.getItem(ACCESS_TOKEN_KEY) !== null;
  const getToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
  const getRefreshToken = () => CookieService.get(REFRESH_ACCESS_TOKEN_KEY);

  const sigInProvider = async (params: IAuthRequest) => {
    const response:IAuthResponse = await signInService(params);
        
    const { access_token, refresh_token, authorization, profileData } = response;

    localStorage.setItem('authData', JSON.stringify(response));
    localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
    localStorage.setItem(USER_ID, profileData.userId.toString());
    localStorage.setItem(CLIENT_ID, profileData.clientId.toString());
    localStorage.setItem(COMPANY_ID, profileData.companyId.toString());
    localStorage.setItem(USER_NAME, profileData.name);
    CookieService.set(REFRESH_ACCESS_TOKEN_KEY, refresh_token, {
      path: '/',
    });

    const authData: AuthData = {
      access_token,
      refresh_token,
      isAdmin: authorization.admin,
      username: profileData.name,
      email: profileData.email,
      persona: profileData.persona,
      clientId: profileData.clientId,
      companyId: profileData.companyId,
      grpCompanies: profileData.grpCompanies
    }; 

    return authData;
  };

  const signOutProvider = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(CLIENT_ID);
    CookieService.remove(REFRESH_ACCESS_TOKEN_KEY);

    return null;
  };

  const getRefreshTokenProvider = async () => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) return;

    const accessToken = await getRefreshTokenService(refreshToken);

    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    return accessToken;
  };

  return {
    CLIENT_ID,
    USER_NAME,
    isAuthenticated,
    getToken,
    getRefreshToken,
    sigInProvider,
    signOutProvider,
    getRefreshTokenProvider,
  };
};
