import styled from 'styled-components';

type PropsTrstyled = {
  height: string;
};

export const TableStyled = styled.table`
  text-align: center;
  width: 100%;
  height: 150px;
  //color: #4f5882;
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

export const Thead = styled.thead`
  height: 40px;
  background: #273dab;
  //background: #4f5882;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 1px 62px;
`;

export const Tbody = styled.tbody`
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
`;

export const Trstyled = styled.tr`
  background: #f4f5ff;
  color: black;
  //font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  height: ${(props: PropsTrstyled) => props.height};
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;
