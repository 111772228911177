import React from 'react';

import { OccupationData } from '../../../../../types/MonitoredData';

type Props = {
  data: OccupationData[];
};

export const ListItem: React.FC<Props> = (props: Props) => {
  const { data } = props;

  return (
    <>
      {data &&
        data.map((occupation: OccupationData, idx: number) => (
          <div className="occupation-container" key={idx}>
            <span className="occupation-qtdy">{occupation.quantity}</span>
            <span className="occupation-title">{occupation.occupation}</span>
          </div>
        ))}
    </>
  );
};
