import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PaginationControls } from '../../../components/Buttons/PaginationControls';
import { IconLoading } from '../../../components/IconLoading';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import Incident from '../../../Icons/Incident';
import { CollaboratorTimelineLabel } from '../../indicators/types/Indicators';
import { useTimeline } from '../../conformity/Timeline/hooks/useTimeline';
import { useMovimentContext } from '../context/MovimentContext';
import CollaboratorTimelineFooter from './CollaboratorTimelineFooter';
import CollaboratorTimelineLine from './CollaboratorTimelineLine';
import { useTimelineContext } from './context/CollaboratorTimelineContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-center;
  justify-content: center;

  @media (max-width: 1000px) {
    & > div {
      padding-top: 2.5rem;
    }
  }

  & > div:first-child {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: auto;
    margin-left: -0.5%;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-left: -0.5%;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & span {
    font: normal 500 1.5rem/1.5 Work Sans, sans-serif;
    color: #4f5882;
  }
`;

const CollaboratorTimeline: React.FC = () => {
  const { initialDate, finalDate, collaboratorSelected } = useMovimentContext();
  const { getCollaboratorTimelineDataApi } = useTrackfyApi();
  const [labels, setLabels] = useState<CollaboratorTimelineLabel[]>([]);
  const { dividerPerEighteen } = useTimeline();
  const {
    timelineValues,
    setTimelineValues,
    timelineData,
    setTimelineData,
    isLoading,
    isEmpty,
    setIsLoading,
    setIsEmpty,
  } = useTimelineContext();

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getCollaboratorTimelineDataApi(
        collaboratorSelected,
        initialDate,
        finalDate,
        currentRequest.token,
        setLabels,
        setTimelineData,
        setTimelineValues,
        dividerPerEighteen,
        setIsLoading,
        setIsEmpty
      );

    return () => {
      mounted = false;
      currentRequest.cancel('CollaboratorTimeline request canceled by the user.');
    };
  }, [collaboratorSelected, initialDate, finalDate]);

  if (isLoading) return <IconLoading />;

  if (isEmpty)
    return (
      <EmptyContainer>
        <Incident />
        <span>Não há dados de movimentação para o período selecionado</span>
      </EmptyContainer>
    );

  return (
    <Container>
      <div>
        {timelineData.map((i) => (
          <CollaboratorTimelineLine
            key={i.id}
            last={i.last}
            background={i.background}
            date={i.date}
            start={i.start}
            width={timelineData.length}
          />
        ))}
      </div>
      <LabelContainer>
        {labels.map((i) => (
          <CollaboratorTimelineFooter key={i.id} name={i.name} background={i.background} />
        ))}
      </LabelContainer>
      <div>
        <PaginationControls
          arrayValues={timelineValues}
          setNewValue={setTimelineData}
          title="Timeline de colaboradores"
        />
      </div>
    </Container>
  );
};

export default CollaboratorTimeline;

/*
  const getTimelineData= async (
    cancelToken: CancelToken,
    projValue?: number,
    collaboratorValue?:number,
  ) => {
    const userId = localStorage.getItem(USER_ID) ? localStorage.getItem(USER_ID) : '1';
    const initDate: number = moment(initialDate).startOf('day').valueOf();
    let fimDate: number = moment(finalDate).valueOf();

    if(finalDate && (new Date(finalDate).getDate() === new Date().getDate()))
        fimDate = moment().valueOf();

    let projectoValue: number | undefined = projValue;
    let colValue: number | undefined = collaboratorValue;

    if (projectSelctValue && projectSelctValue !== 0) projectoValue = projectSelctValue;
    if (collaboratorsSelctValue && collaboratorsSelctValue !== 0) colValue = collaboratorsSelctValue;

    setIsEmpty(false);
    setIsLoading(true);

    return fetchData(
      token,
      `${baseUrl}/statistics/collaboratorTimeline`,
      {
        project: projectoValue,
        collaboratorId: colValue,
        userId:userId,
        initialDate: initDate,
        finalDate: fimDate
      },
      cancelToken
    );
  };
  */

/*
    useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted: boolean = true;

    getTimelineData(currentRequest.token, projectSelctValue, collaboratorsSelctValue)
    .then((response: AxiosResponse<any>) => {
      if (mounted) {
        if(!response.data.timeline || response.data.timeline.length === 0){
            setLabels([]);
            setIsEmpty(true);
        } else {
          const dividerPerEighteen = (base: any[]): Array<any[]> => {
            const divisor: number = 18;
            const newArray: Array<any[]> = [];

            for (let i = 0; i < base.length; i += divisor) {
              const divider: any[] = base.slice(i, i + divisor);
              newArray.push(divider);
            }
            return newArray;
          };

          batchedUpdates(() => {
            setTimelineValues(dividerPerEighteen(response.data.timeline));
            setTimelineData(dividerPerEighteen(response.data.timeline)[0]);
          });

          setLabels(response.data.labels);
        }
        setIsLoading(false);
      }
    })
    .catch((error: AxiosResponse<any>) => console.log(error));

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [initialDate, finalDate, projectSelctValue, collaboratorsSelctValue]);
  */
