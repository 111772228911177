import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../../Icons/Users';
import { useRankContext } from './context/RankContext';
import RankChartLabel from './RankChartLabel';

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  & > svg {
    margin-right: 1.6rem;
    margin-top: 0.3rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0.6rem;
`;

const SummaryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  justify-content: space-between;

  @media (max-width: 1280px) {
    &&& {
      gap: 3rem;
    }
  }
`;

const RankSummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

const RankDescription = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 1.5rem;

  @media (max-width: 576px) {
    &&& {
      font-size: 1.2rem;
    }
  }
`;

const AverageDescription = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
  //margin-bottom: 1.5rem;
  margin-bottom: 27px;

  @media (max-width: 576px) {
    &&& {
      font-size: 1.2rem;
    }
  }
`;

/* const SearchContainer = styled.div`
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: column;
`;

const InputSearch = styled(Input.Search)`
  &&& {
    width: 100%;
    & > .ant-input-wrapper {
      position: relative;
    }
    & > .ant-input-affix-wrapper {
    }
    &.ant-input-search .ant-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #ffffff;
      border: 1px solid #e4e8fb;
      border-radius: 8px;
      height: 46px;
      transition: border 0.3s ease-in-out;
      font: normal 400 1.4rem/1.5 Work Sans, sans-serif;
      color: #4f5882;
      border: 1px solid #5669c8;

      &:hover {
        border: thin solid #2f4cddbe;
      }
      &:focus {
        box-shadow: 0 0 0px 2.5px #2f4cdd44;
        border: thin solid #2f4cddbe;
      }

      &::placeholder {
        color: #4f5882;
      }
    }

    & .ant-input-group-addon {
      position: absolute;
      left: auto;
      right: 45px;
      border: 0;
      top: 0;
      background: transparent;
      z-index: 99;

      & > button {
        height: 46px;
        width: 46px;
        background: transparent;
        border: 0;

        &:after {
          border-radius: 8px;
        }
      }
      & svg {
        fill: #2f4cdd;
      }
    }
  }
`; */

const RankChartHeader: React.FC = () => {
  const { higherProductivity, lowerProductivity, average, isLoadingAverage } = useRankContext();
  // const { isLoadingAverage } = useRank();

  /* const onSearch = async (value: string) => {
    setRankSearchValue(value);
  }; */

  return (
    <Header>
      <Titlecontainer>
        <IconUsers />
        <Title>Ranking de Produtividade</Title>
      </Titlecontainer>

      <SummaryContainer>
        <RankSummaryContainer>
          <RankDescription>
            Maior <br /> produtividade
          </RankDescription>
          <RankChartLabel
            value={higherProductivity}
            isLoadingAverage={isLoadingAverage}
            percentage
          />
        </RankSummaryContainer>

        <RankSummaryContainer>
          <RankDescription>
            Menor <br /> produtividade
          </RankDescription>
          <RankChartLabel
            value={lowerProductivity}
            isLoadingAverage={isLoadingAverage}
            percentage
          />
        </RankSummaryContainer>

        <RankSummaryContainer>
          <AverageDescription>Média</AverageDescription>
          <RankChartLabel value={average} isLoadingAverage={isLoadingAverage} percentage />
        </RankSummaryContainer>
      </SummaryContainer>

      {/* <SearchContainer>
        <InputSearch
          id="rankSearchInput"
          placeholder="Pesquise por um colaborador"
          onSearch={onSearch}
        />
      </SearchContainer> */}
    </Header>
  );
};

export default RankChartHeader;
