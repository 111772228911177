import moment from 'moment';
import { useState } from 'react';
import tippy from 'tippy.js';

import { CalendarEvent } from '../../../indicators/types/Indicators';

export const useCalendar = () => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [initDate, setInitDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const logData = (e: any) => e;

  const showEventDetails = (e: any) => {
    if (e.event.endStr.length > 0) {
      const title: string = e.event._def.extendedProps.tooltip;
      const enterAreaTimestamp: string = moment(e.event.start).format('DD/MM/YY HH:mm');
      const exitAreaTimestamp: string = moment(e.event.end).format('DD/MM/YY HH:mm');
      const contentHtml: string =
        `${"<div class='tooltipTitleContainer'>" + "<div style='background: "}${
          e.event.backgroundColor
        }class='pointlabel'></div>` +
        `<h5 class='tooltipTitle'><strong>${title}</strong></h5>` +
        `</div>` +
        `<h5 class='tooltipEnter'>entrada: ${enterAreaTimestamp}</h5>` +
        `<h5 class='tooltipExit'>saida: ${exitAreaTimestamp}</h5>`;
      tippy(e.el, {
        allowHTML: true,
        content: contentHtml,
        theme: 'custom',
      });
    }
  };

  return {
    events,
    setEvents,
    initDate,
    setInitDate,
    isLoading,
    setIsLoading,
    showEventDetails,
    logData,
  };
};
