import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../Icons/Users';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1.6rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const CollaboratorTimelineHeader: React.FC = () => (
  <Header>
    <Titlecontainer>
      <IconUsers />
      <Title>Linha do Tempo de produtividade</Title>
    </Titlecontainer>
  </Header>
);

export default CollaboratorTimelineHeader;
