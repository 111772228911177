import { ConfigKey } from '../enum/ConfigKey';

const defaultConfig: Record<ConfigKey, any> = {
  // Debug mode
  DEBUG: false,

  // The node environment (development | test | production)
  NODE_ENV: 'development',

  REACT_APP_SERVER_HOST: 'http://localhost:8083/rest',
  // REACT_APP_SERVER_HOST: "https://trackfy-backend-dev.herokuapp.com/rest",
  REACT_APP_USE_MOCKS: true,
};

export const getConfig = (key: ConfigKey): string => process.env[key] || defaultConfig[key];
