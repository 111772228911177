import { GetUserGroupPermissions, UserGroupPermissions } from '.';
import { MenuIcon, RouteKey } from '../../../enum';

export const getUserGroupPermissions3: GetUserGroupPermissions = async () => {
  return await getUserGroupPermissions2();
}

export function getUserGroupPermissions2(): UserGroupPermissions {
  let routes =  [
    {
      key: RouteKey.monitoramento,
      path: '/monitoramento',
    },
    {
      key: RouteKey.indicadores,
      path: '/indicadores',
    },
    {
      key: RouteKey.sumario,
      path: '/sumario',
    },
    {
      key: RouteKey.relatorios,
      path: '/relatorios',
    },
  ];

  let menu = [
    {
      key: 'monitoramento',
      description: 'Monitoramento',
      icon: MenuIcon.dashboard,
      path: '/monitoramento',
    },
    {
      key: 'indicadores',
      description: 'Indicadores',
      icon: MenuIcon.indicators,
      path: '/indicadores',
    },
    {
      key: 'sumario',
      description: 'Sumario',
      icon: MenuIcon.summary,
      path: '/sumario',
    },
    {
      key: 'relatorios',
      description: 'Relatórios',
      icon: MenuIcon.reports,
      path: '/relatorios',
    },
  ];

  const authDataString: any = localStorage.getItem('authData');
  
  if(authDataString){
    const authData = JSON.parse(authDataString);
    routes = authData.profileData.routes;
    menu = authData.profileData.menu;
  }
  
  return {
    rules: ['ADMIN'],
    routes: routes,
    menu: menu
  };
}

export const getUserGroupPermissions: GetUserGroupPermissions = async () => ({
  rules: ['ADMIN'],
  routes:
  [
    {
      key: RouteKey.monitoramento,
      path: '/monitoramento',
    },
    {
      key: RouteKey.indicadores,
      path: '/indicadores',
    },
    {
      key: RouteKey.sumario,
      path: '/sumario',
    },
    {
      key: RouteKey.relatorios,
      path: '/relatorios',
    },
  ],
  menu: [
    {
      key: 'monitoramento',
      description: 'Monitoramento',
      icon: MenuIcon.dashboard,
      path: '/monitoramento',
    },
    {
      key: 'indicadores',
      description: 'Indicadores',
      icon: MenuIcon.indicators,
      path: '/indicadores',
    },
    {
      key: 'sumario',
      description: 'Sumario diário',
      icon: MenuIcon.summary,
      path: '/sumario',
    },
    {
      key: 'relatorios',
      description: 'Relatórios',
      icon: MenuIcon.reports,
      path: '/relatorios',
    },
  ],
});
