import { Line, LineConfig } from '@ant-design/charts';
import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { IconLoading } from '../../../components/IconLoading';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { BenchmarkData, Planning } from '../../indicators/types/Indicators';
import { useConformityContext } from '../context/ConformityContext';

const BurndownChart: React.FC = () => {
  const { areaSelected, companySelected, professionSelected, initialDate, finalDate } =
    useConformityContext();
  const { getBurndownChartDataApi } = useTrackfyApi();
  const [planningData, setPlanningData] = useState<Planning[]>([]);
  const [benchmarkData, setBenchmarkData] = useState<BenchmarkData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const color = ['#4F5882', '#00E0B4', '#468FEA'];

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    getBurndownChartDataApi(
      companySelected,
      professionSelected,
      areaSelected,
      moment(initialDate).valueOf(),
      moment(finalDate).valueOf(),
      currentRequest.token,
      setIsLoading,
      setPlanningData,
      setBenchmarkData
    );

    return () => {
      currentRequest.cancel('BurndownChart request canceled by the user.');
    };
  }, [areaSelected, companySelected, professionSelected, initialDate, finalDate]);

  const config: LineConfig = {
    data: planningData,
    xField: 'time',
    yField: 'value',
    color: color,
    seriesField: 'type',
    point: {
      size: 5,
      shape: 'diamond | circule',
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) { return ''.concat(v, 'hrs'); }
      }
    },
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: 'programado',
          name: 'Programado (prévia)',
          marker: {
            symbol: 'square',
            style: {
              fill: '#4F5882',
              r: 5,
            },
          },
        },
        {
          value: 'executado',
          name: 'Executado (informado)',
          marker: {
            symbol: 'square',
            style: {
              fill: '#00E0B4',
              r: 5,
            },
          },
        },
        {
          value: 'trackfy',
          name: 'Realizado (Trackfy)',
          marker: {
            symbol: 'square',
            style: {
              fill: '	#468FEA',
              r: 5,
            },
          },
        },
      ],
    },
  };

  if (isLoading) return <IconLoading customDivStyle={{marginTop:"100px", marginLeft:"45%", marginRight:"45%"}}/>;

  return <Line {...config} />;
};

export default BurndownChart;
