import { AverageTimeByPeople } from '../../../../indicators/types/Indicators';

export const useAverageTime = () => {
  const dividerPerFive = (base: AverageTimeByPeople[]): Array<AverageTimeByPeople[]> => {
    const divisor: number = 5;
    const newArray: Array<AverageTimeByPeople[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: AverageTimeByPeople[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  return {
    dividerPerFive,
  };
};
