import { CancelToken } from 'axios';

import { GetCompanies, GetHeatMapData, GetMachinesSummary, GetTimelineData } from '.';
import { IHeatmapArea } from '../../../containers/moviment/HeatMap/types/HeatMapData';

const mockedData = [
  { occupation: 'Almoxarife', quantity: 1 },
  { occupation: 'Aux Montagem de Andaime', quantity: 2 },
  { occupation: 'Caldereiro', quantity: 8 },
  { occupation: 'Eletricista', quantity: 4 },
  { occupation: 'Encarregado de Civil', quantity: 1 },
  { occupation: 'Engenheiro Civil', quantity: 1 },
  { occupation: 'Funileiro', quantity: 1 },
  { occupation: 'Gerente de Produção', quantity: 2 },
  { occupation: 'Instrumentista', quantity: 4 },
  { occupation: 'Isolador Termico', quantity: 1 },
  { occupation: 'Mecânico', quantity: 3 },
  { occupation: 'Meio Oficial Refrigeração', quantity: 1 },
  { occupation: 'Montador de Andaime', quantity: 8 },
  { occupation: 'Pedreiro', quantity: 2 },
  { occupation: 'Pintor', quantity: 4 },
  { occupation: 'Servente', quantity: 1 },
  { occupation: 'Soldador', quantity: 1 },
  { occupation: 'Supervisor de Projetos', quantity: 1 },
  { occupation: 'Tec Seg do Trabalho', quantity: 1 },
  { occupation: 'Técnico Refrigeração', quantity: 1 },
];

export const getTimelineData: GetTimelineData = async () => [
  {
    first: true,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '00:00',
    count: '5',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '01:00',
    count: '3',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '02:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '03:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '04:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '05:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '06:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '07:00',
    count: '0',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '08:00',
    count: '34',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '09:00',
    count: '18',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '10:00',
    count: '21',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '11:00',
    count: '7',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '12:00',
    count: '8',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '13:00',
    count: '15',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '14:00',
    count: '14',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '15:00',
    count: '22',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '16:00',
    count: '23',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '17:00',
    count: '9',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '18:00',
    count: '2',
    data: mockedData,
  },
  {
    first: false,
    blink: false,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '19:00',
    count: '5',
    data: mockedData,
  },
  {
    first: false,
    blink: true,
    color: '#2f4cdd',
    background: '#2f4cdd',
    date: '2021-12-14',
    time: '20:00',
    count: '3',
    data: mockedData,
  },
  {
    first: false,
    date: '14/12/2021',
    time: '21:00',
    count: '0',
    background: '#c4c4c4',
    color: '#ffffff',
    blink: false,
    data: mockedData,
  },
  {
    first: false,
    date: '14/12/2021',
    time: '22:00',
    count: '0',
    background: '#c4c4c4',
    color: '#ffffff',
    blink: false,
    data: mockedData,
  },
  {
    first: false,
    date: '14/12/2021',
    time: '23:00',
    count: '0',
    background: '#c4c4c4',
    color: '#ffffff',
    blink: false,
    data: mockedData,
  },
];

export const getCompanies: GetCompanies = async (operational:boolean, _cancelToken?: CancelToken) => [
  { id: 1, name: 'Limite Industria', cnpj: '12345678912345', razao_social: 'Limite Industria' },
  { id: 2, name: 'Industria Rack', cnpj: '12345678912345', razao_social: 'Industria Rack' },
  { id: 3, name: 'Bônus Industria', cnpj: '12345678912345', razao_social: 'Bônus Industria' },
  { id: 4, name: 'Jubileu Industria', cnpj: '12345678912345', razao_social: 'Jubileu Industria' },
  { id: 5, name: 'Industria Fado', cnpj: '12345678912345', razao_social: 'Industria Fado' },
  { id: 6, name: 'Industria Energia', cnpj: '12345678912345', razao_social: 'Industria Energia' },
  { id: 7, name: 'Resoluto Energia', cnpj: '12345678912345', razao_social: 'Resoluto Energia' },
  { id: 8, name: 'Nobre Lamina', cnpj: '12345678912345', razao_social: 'Nobre Lamina' },
  { id: 9, name: 'Península Cimento', cnpj: '12345678912345', razao_social: 'Península Cimento' },
];

export const getMachinesSummary: GetMachinesSummary = async (_cancelToken?: CancelToken) => ({
  data: [
    {
      partnumber: '1',
      alias: 'Empilhadeira 1',
      location: 'Pia úmido',      
      timestamp: '02/05/2022 10:00:00',
      status: '$4',
    },
    {
      partnumber: '2',
      alias: 'Empilhadeira 2',
      location: 'Pia Seco',
      timestamp: '06/05/2022 10:30:00',
      status: '$4',
    },
    {
      partnumber: '3',
      alias: 'Transpaleteira 1',    
      location: 'Almoxarifado',
      timestamp: '04/05/2022 09:00:00',
      status: '$4',
    },
    {
      partnumber: '4',
      alias: 'Transpeleteira 2',
      location: 'Dsida',
      timestamp: '23/05/2022 10:30:00',
      status: '$4',
    },
    {
      partnumber: '5',
      alias: 'Transpaleteira 3',
      location: 'Oficina',
      timestamp: '01/05/2022 10:30:00',
      status: '$4',
    },
    {
      partnumber: '6',
      alias: 'Transpaleteira patolada 1',
      location: 'Pcl3',
      timestamp: '15/05/2022 10:30:00',
      status: '$4',
    },
  ],
  idValues: ['1','2','3','4','5','6'],
  statusValues: ['em espera', 'em manutenção', 'em uso', 'em espera', 'em manutenção', 'em uso'],
});

export const putMachineStatus: any = async (status: string,id: string,cancelToken?: CancelToken): Promise<any> => {
  console.log(id + " / " + status + " / " + cancelToken);
};

export const getHeatMapData: GetHeatMapData = async (companyId: number, professionId: number, initialDate: number, finalDate: number, 
  cancelToken: CancelToken): Promise<IHeatmapArea[]> => [
    {
      value: 2.78,
      name: "AREA 1",
      center: [-12.654476030737978, -38.30130636692047],
      radius: 20,
      color: "orange",
      fillColor: "orange",
      opacity: 1.0
    },
    {
      value: 0.10,
      name: "AREA 2",
      center: [-12.656067195364894, -38.300646543502815],
      radius: 15,
      color: "yellow",
      fillColor: "yellow",
      opacity: 0.6
    },
    {
      value: 0.33,
      name: "AREA 3",
      center: [-12.654533605946835, -38.30104351043702],
      radius: 15,
      color: "yellow",
      fillColor: "yellow",
      opacity: 0.6
    }
]
  

