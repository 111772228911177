import { AxiosResponse } from 'axios';
import { GeoJSON } from 'geojson';
import React, { useEffect, useState } from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';
import useSwr from 'swr';

import { useIsMounted, useAuth } from '../../../../../hooks';
import cookieService from '../../../../../services/CookieService';
import { fetchData } from '../../../../../services/requests';
import { useAreasContext } from '../../../../map/context/AreasContext';
import { MapAreasData } from '../../../../monitoring/types/Monitoring';

export const useArea = () => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const { mapAreas, updateMapAreas } = useAreasContext();
  const token: any = cookieService.get('token');

  const { CLIENT_ID } = useAuth();

  const isMounted = useIsMounted();

  useSwr(
    mounted ? 'monitoring/simpleareas' : null,
    async (url: string) => {
      //const clientId = localStorage.getItem(CLIENT_ID) ? localStorage.getItem(CLIENT_ID) : '2';

      await fetchData(token, url, {})
        .then((res: AxiosResponse<any>) => {
          if (finished) return;
          if (!res.data || res.data.length === 0) return;

          if (!isMounted.current) return;

          updateMapAreas(res.data);
        })
        // eslint-disable-next-line no-console
        .catch((error: any) => console.error(error));
    },
    {
      focusThrottleInterval: 3600001,
      refreshInterval: 3600000,
    }
  );

  useEffect(() => {
    setMounted(true);

    return () => {
      setFinished(true);
    };
  }, [mapAreas]);

  const parseDataToCoordinates = (coordinates: string) => {
    try {
      const coordinatesFormated = coordinates
        .replace('"', '')
        .split('],[')
        .map((coord: any) =>
          coord
            .replaceAll('[', '')
            .replaceAll(']', '')
            .split(',')
            .map((value: string) => Number(value))
        );

      return coordinatesFormated;
    } catch (_error) {
      return [];
    }
  };

  function createArea() {
    const areas = mapAreas
      .filter((area: MapAreasData) => area.coordenadas)
      .map((area: MapAreasData, idx: number) => {
        const { nome, cor, coordenadas } = area;

        const coordinates = parseDataToCoordinates(coordenadas);

        const geojson: GeoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                title: nome,
              },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
              },
            },
          ],
        };

        return (
          <GeoJSONLayer
            key={idx}
            id={nome}
            data={geojson}
            fillPaint={{
              'fill-color': cor,
              'fill-opacity': 0.4,
            }}
          />
        );
      });

    return areas;
  }

  return { mapAreas, createArea };
};
