import { useTrackfyApi } from '../../../../../hooks';
import { useReportsContext } from '../../../context/ReportsContext';

export const usePageContent = () => {
  const {
    companySelected,
    professionSelected,
    collaboratorSelected,
    initialDate,
    finalDate,
    loadingAccessControl,
    loadingAreaMonitoringHistory,
    loadingDailyReport,
    setLoadingAccessControl,
    setLoadingAreaMonitoringHistory,
    setLoadingDailyReport,
  } = useReportsContext();
  const { generateAccessManagementApi, generateAreaMonitoringHistoryApi, generateDailyReportApi } =
    useTrackfyApi();

  return {
    companySelected,
    professionSelected,
    collaboratorSelected,
    initialDate,
    finalDate,
    loadingAccessControl,
    loadingAreaMonitoringHistory,
    loadingDailyReport,
    setLoadingAccessControl,
    setLoadingAreaMonitoringHistory,
    setLoadingDailyReport,
    generateAccessManagementApi,
    generateAreaMonitoringHistoryApi,
    generateDailyReportApi,
  };
};
