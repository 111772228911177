import { useState } from 'react';

import { pendoTrack } from '../../../../../services/SystemMonitor';

export const useCard = () => {
  const [cardExpanded, setCardClassName] = useState<boolean>(false);

  const handleClickExpand = (e: MouseEvent) => {
    e.preventDefault();
    pendoTrack('Tela de monitoramento', 'Card de áreas monitoradas', 'Clique');
    setCardClassName(!cardExpanded);
  };

  return {
    cardExpanded,
    handleClickExpand,
  };
};
