import React from 'react';

import { IconLoading } from '../../../../components/IconLoading';
import { usePageContent } from './hooks/usePageContent';
import * as S from './styles';

export const PageContent: React.FC = () => {
  const {
    companySelected,
    professionSelected,
    collaboratorSelected,
    initialDate,
    finalDate,
    loadingAccessControl,
    loadingAreaMonitoringHistory,
    loadingDailyReport,
    setLoadingAccessControl,
    setLoadingAreaMonitoringHistory,
    setLoadingDailyReport,
    generateAccessManagementApi,
    generateAreaMonitoringHistoryApi,
    generateDailyReportApi,
  } = usePageContent();

  return (
    <S.Container>
      <S.Ul>
        <li>
          <div>
            <span>Controle de Acesso</span>
            {loadingAccessControl ? (
              <div>
                <IconLoading customStyle={{ fontSize: 35, marginRight: '10px' }} />
              </div>
            ) : (
              <S.GenerateReport
                onClick={() => {
                  generateAccessManagementApi(
                    companySelected,
                    professionSelected,
                    collaboratorSelected,
                    initialDate,
                    finalDate,
                    setLoadingAccessControl
                  );
                }}
              >
                Imprimir
              </S.GenerateReport>
            )}
          </div>
        </li>
        <li>
          <div>
            <span>Histórico de Permanência nas Áreas</span>
            {loadingAreaMonitoringHistory ? (
              <div>
                <IconLoading customStyle={{ fontSize: 35, marginRight: '10px' }} />
              </div>
            ) : (
              <S.GenerateReport
                onClick={() => {
                  generateAreaMonitoringHistoryApi(
                    companySelected,
                    professionSelected,
                    collaboratorSelected,
                    initialDate,
                    finalDate,
                    setLoadingDailyReport,
                    setLoadingAreaMonitoringHistory
                  );
                }}
              >
                Imprimir
              </S.GenerateReport>
            )}
          </div>
        </li>
        {/*<li>
          <div>
            <span>Relatório Diário Sistema</span>
            {loadingDailyReport ? (
              <div>
                <IconLoading customStyle={{ fontSize: 35, marginRight: '10px' }} />
              </div>
            ) : (
              <S.GenerateReport
                onClick={() => {
                  generateDailyReportApi(
                    companySelected,
                    professionSelected,
                    setLoadingDailyReport,
                    setLoadingAreaMonitoringHistory
                  );
                }}
              >
                Imprimir
              </S.GenerateReport>
            )}
          </div>
              </li>*/}
      </S.Ul>
    </S.Container>
  );
};
