import React from 'react';

export type Header = {
  title: string;
};

export const headers: Header[] = [
  {
    title: 'Partnumber',
  },
  {
    title: 'Identificação',
  },
  {
    title: 'Localização',
  },
  {
    title: 'Data',
  },
  {
    title: 'Status',
  },
];