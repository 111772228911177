import React from 'react';

import Incident from '../../Icons/Incident';
import { IconLoading } from '../IconLoading';
import { Tbody, Trstyled, EmptyContainer } from './styled';

type TableBodyProps = {
  tableData: Array<string[]>;
  loading: boolean;
  isEmpty: boolean;
};

const TableBody: React.FC<TableBodyProps> = (props: TableBodyProps) => {
  const { tableData, loading, isEmpty } = props;

  if (loading)
    return (
      <Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <IconLoading />
          </td>
        </tr>
      </Tbody>
    );

  if (isEmpty)
    return (
      <Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <EmptyContainer>
              <Incident />
              <span>Não há dados para o período selecinado</span>
            </EmptyContainer>
          </td>
        </tr>
      </Tbody>
    );

  return (
    <Tbody>
      {tableData.map((item: string[], index: number) =>
        index % 2 === 0 ? (
          <Trstyled key={`${index}-${item}`} height={'22px'}>
            {item.map((val) => (
              <td key={`${index}-${val}`}>{val}</td>
            ))}
          </Trstyled>
        ) : (
          <tr key={`${index}-${item}`} style={{fontWeight:500}}>
            {item.map((val) => (
              <td key={`${index}-${val}`}>{val}</td>
            ))}
          </tr>
        )
      )}
    </Tbody>
  );
};

export default TableBody;
