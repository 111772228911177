import React from 'react';

import { History } from './components/History';
import { Search } from './components/Search';

export const DetectiveContainer: React.FC = () => (
  <>
    <Search />
    <History />
  </>
);
