import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useRef } from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { IconLoading } from '../../../../components/IconLoading';
import { ProgressBar } from '../../../../components/ProgressBar';
import { useTrackfyApi } from '../../../../hooks/useTrackfyApi';
import { RankByProfessionData } from '../../../indicators/types/Indicators';
import { useProductivityContext } from '../../context/PageProductivityContext';
import { useRankContext } from './context/RankContext';
import { useRank } from './hooks/useRank';

const RankChart: React.FC = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { setLowerProductivity, setHigherProductivity, setAverage, setIsLoadingAverage } =
    useRankContext();
  const { getRankChartDataApi } = useTrackfyApi();
  const { rankChartData, isLoading, getColor, setRankChartData, setIsLoading } = useRank();
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getRankChartDataApi(
        companySelected,
        professionSelected,
        initialDate,
        finalDate,
        currentRequest.token,
        setLowerProductivity,
        setHigherProductivity,
        setAverage,
        setRankChartData,
        setIsLoading,
        setIsLoadingAverage
      );

    return () => {
      currentRequest.cancel('RankChart request canceled by the user.');
      mounted = false;
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  if (isLoading) return <IconLoading />;
  return (
    <ScrollBar ref={ScrollbarsRef}>
      <div style={{ width: '100%' }}>
        {rankChartData
          .map((person: RankByProfessionData) => (
            <div key={person.name}>
              <ProgressBar
                label={person.name}
                value={person.value}
                color={getColor(person.value)}
              />
            </div>
          ))
          .sort((a: JSX.Element, b: JSX.Element) => {
            const newValue: number = a.props.children.props.value;
            const oldValue: number = b.props.children.props.value;

            if (newValue < oldValue) return 1;
            if (newValue > oldValue) return -1;
            return 0;
          })}
      </div>
    </ScrollBar>
  );
};

export default RankChart;
