import { List } from 'antd';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import { IconLoading } from '../../../../../components/IconLoading';
import { useIsMounted } from '../../../../../hooks';
import IconArrowRight from '../../../../../Icons/ArrowRight';
import IconDot from '../../../../../Icons/Dot';
import { CLIENT_ID } from '../../../../../services/auth';
import cookieService from '../../../../../services/CookieService';
import { fetchData } from '../../../../../services/requests';
import { useCompanyFilterContext } from '../../../../../stores/CompanyFilterContext';
import { useDetectiveContext } from '../../../context/DetectiveContext';

type Collaborator = {
  id: number;
  collaborator: string;
  historic: [
    {
      color: string;
      area: string;
    }
  ];
};

type Props = {
  collaborator: Collaborator;
  onSearch: (value?: string) => any;
};

export const ResultList: React.FC<Props> = (props: Props) => {
  const {
    selectedPerson,
    updateSelectedPerson,
    updateDataMonitoredCollaborators,
    initHistory,
    setInitHistory,
  } = useDetectiveContext();
  const { collaborator, onSearch } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const token: any = cookieService.get('token');
  const clientId: string | null = localStorage.getItem(CLIENT_ID)
    ? localStorage.getItem(CLIENT_ID)
    : '2';
  const isMounted = useIsMounted();

  const { selectedCompany } = useCompanyFilterContext();
  const companyId = selectedCompany?.id || '';

  const getCollaborators = async () => {
    await fetchData(token, `/monitoring/collaborators`, { clientId, companyId })
      .then((res: AxiosResponse<any>) => {
        const { data } = res;

        if (!isMounted.current) return;

        updateDataMonitoredCollaborators(data);
      })
      .catch(() => {
        if (!isMounted.current) return;
        updateDataMonitoredCollaborators([]);
      })
      .finally(() => {
        if (!isMounted.current) return;

        onSearch('');
      });
  };

  const handleClickOpenHistory = useCallback(
    (e: any) => {
      e.preventDefault();
      updateSelectedPerson(0);
      setInitHistory(false);
      let { id } = e.target.parentElement;
      id = id || e.target.parentElement.parentElement.id;
      id = id || e.target.parentElement.parentElement.parentElement.id;

      updateSelectedPerson(id);
      getCollaborators();
      setLoading(true);
    },
    [loading, selectedPerson]
  );

  useEffect(() => {
    if (initHistory) return setLoading(false);
  }, [initHistory]);

  return (
    <>
      {collaborator && (
        <List.Item id={collaborator.id.toString()} className="search-list-item">
          <div className="list-label-collaborator">
            {collaborator.collaborator}
            {/* abridgedControl(collaborator.collaborator, 10) */}
          </div>
          <IconDot fill={collaborator.historic[0].color} />
          <div className="list-label-area">{collaborator.historic[0].area}</div>
          {loading ? (
            <IconLoading customStyle={{ width: '20px' }} />
          ) : (
            <div className="list-action-open" onClick={handleClickOpenHistory}>
              <IconArrowRight disabled={loading} />
            </div>
          )}
        </List.Item>
      )}
    </>
  );
};
