import React from 'react';
import { Popup } from 'react-mapbox-gl';

import { HistoryHeader, HistoryContent } from './components';
import { useHistory } from './hooks/useHistory';

import './styles.css';

export const History: React.FC = () => {
  const {
    lng,
    lat,
    historic,
    initHistory,
    collaborator,
    loading,
    currentLocation,
    handleClickToClose,
    setPeriodHistory,
  } = useHistory();

  return (
    <>
      {initHistory && (
        <Popup coordinates={[lng, lat]} className="popup-container">
          <HistoryHeader
            title={collaborator}
            currentLocation={currentLocation}
            onClickToClose={handleClickToClose}
          />
          <HistoryContent
            historic={historic}
            onChangePeriodHistory={setPeriodHistory}
            loading={loading}
          />
        </Popup>
      )}
    </>
  );
};
