import { SignIn, GetRefreshToken } from '.';

export const signIn: SignIn = async () => ({
  access_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
  refresh_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
  profileData: {
    clientId: 1,
    companyId:1,
    grpCompanies:"t",
    userId: 1,
    name: 'admin',
    email: 'admin@email.com',
    persona: 'personaMock',
    mainPage: '/monitoramento',
    routes: [],
    menu: []
  },
  authorization: {
    superUser: true,
    admin: true,
    rules: ['ADMIN'],
  },
});

export const getRefreshToken: GetRefreshToken = async (): Promise<string> =>
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
