import styled from 'styled-components';

type PropsContentContainer = {
  value: string;
}

export const Container = styled.div`
  background: #0823ad;
  width: 100%;
  min-height: 100vh;
  padding: 2.6rem 1.6rem 1.6rem;

  @media (max-width: 576px) {
    padding: 2.6rem 0 1.6rem;
  }

  //@media (max-height: 606px) {
    //height: 100%;
  //}
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  @media (max-width: 576px) {
    padding-left: 1.5rem;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgb(85 85 85 / 8%) 0px 2px 8px 2px;
  padding: 1.2em;
  //margin-top: 12px;
  //padding: 0.5em;

  & > div {
    width: 233px;
    //padding: 0 12px 0 0;
  }

  @media (max-width: 1512px) {
    & > div {
      width: 24%;
    }
  }

  @media (max-width: 1024px) {
    & > div {
      padding: 3px 0 0 0;
      width: 48%;
    }
  }

  @media (max-width: 500px) {
    & > div {
      width: 96%;
    }
  }
`;

export const Title = styled.h1`
  font: normal 500 2.8rem/1.25 Work Sans, sansserif;
  color: #f8f8f8;

  &:after {
    content: '';
    display: block;
    border-radius: 8px;
    border: 1px solid #42dbb6;
  }
`;

export const ContentContainer = styled.div`
  //min-height: 40vh;
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3.5rem;
  padding: 3.5rem 2.4rem;
  grid-template-columns: ${(props: PropsContentContainer) => props.value.length > 0 ? props.value: ''};

  background: #f3f3f3;
  border-top-style: none;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 576px) {
    border-top-right-radius: 0;
    padding: 2.5rem 1.5rem;
  }
`;