import { Menu as MenuAntd } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Menu = styled(MenuAntd)`
  &&& {
    padding: 0 1.8rem;
    margin-top: 2rem;
    border: 0;
    max-height: calc(100vh - (110px + 39px) - 20px);
    overflow: hidden auto;
    touch-action: auto;
    scrollbar-width: thin;
    scrollbar-color: #48435c80 transparent;

    &.ant-menu-inline-collapsed {
      max-height: calc(100vh - (146px + 39px) - 20px);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #48435c80;
      border-radius: 30px;
    }

    &.ant-menu-inline-collapsed {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      max-width: 52px;
      margin-left: auto;
      margin-right: auto;

      & > li.ant-menu-item,
      & > li.ant-menu-submenu-open > .ant-menu-submenu-title,
      & > li > .ant-menu-submenu-title {
        width: 52px;
        height: 52px;
        flex: 0 0 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        & svg {
          margin: 0;
        }
        &:before {
          top: 8px;
        }
      }

      & > li span,
      & > li i,
      & > li a {
        display: none;
      }
    }
  }
`;

/* & li:nth-child(4),
    & li:nth-child(6) {
      text-transform: capitalize;
      & > svg,
      & > div > svg {
        fill: none;
        & path {
          stroke: #fff;
        }
      }

      &.ant-menu-submenu-open > div > svg,
      &.ant-menu-item-selected > svg,
      &:hover > svg,
      &:hover > div > svg {
        fill: none;
        & path {
          stroke: #2f4cdd;
        }
      }
    } */

export const SubMenu = styled(MenuAntd.SubMenu)`
  &&& {
    & > ul {
      padding-left: 1.2rem;
    }
    & .ant-menu-title-content {
      font: normal 400 1.6rem/1.5 Work Sans, sans-serif;
      color: #fff;
    }

    & i.ant-menu-submenu-arrow {
      color: #fff;
    }

    &.ant-menu-submenu-open {
      .ant-menu-submenu-title {
        background: #fafbff !important;
        & svg {
          fill: #2f4cdd;

          &:after {
            content: '';
            background: #e4e8fb;
            border-radius: 50%;
          }
        }

        & .ant-menu-title-content {
          font-weight: 600;
          color: #2f4cdd;
        }

        & i {
          color: #2f4cdd !important;
        }
      }
    }

    & .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      border-radius: 64px;
      padding: 1.6rem 0.8rem !important;
      height: 46px;

      &:hover {
        &:before {
          content: '';
          background: #e4e8fb;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          display: block;
          position: absolute;
          z-index: 0;
          top: 5px;
          left: 8px;
        }

        background: #fafbff !important;

        & svg {
          fill: #2f4cdd;
          z-index: 1;
          &:after {
            content: '';
            background: #e4e8fb;
            border-radius: 50%;
          }
        }

        & i.ant-menu-submenu-arrow {
          color: #2f4cdd;
        }

        & .ant-menu-title-content {
          font-weight: 600;
          color: #2f4cdd;
        }
      }

      & svg {
        fill: #fff;
        margin-right: 1.4rem;
        margin-left: 0.5rem;
        width: 24px;
        height: 24px;
      }
    }
  }
`;

const MenuAntdItem = styled(MenuAntd.Item)`
  &&& {
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 64px;
    padding: 1.6rem 0.8rem !important;
    height: 46px;

    &:after {
      transform: scaleY(0);
    }

    &:hover,
    &.ant-menu-item-selected {
      &:before {
        content: '';
        background: #e4e8fb;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: block;
        position: absolute;
        z-index: 0;
        top: 5px;
        left: 8px;
      }

      background: #fafbff !important;

      & svg {
        fill: #2f4cdd;

        &:after {
          content: '';
          background: #e4e8fb;
          border-radius: 50%;
        }
      }

      & a {
        font-weight: 600;
        color: #2f4cdd;
      }
    }

    & svg {
      fill: #fff;
      margin-right: 1.4rem;
      margin-left: 0.5rem;
      width: 24px;
      height: 24px;
      z-index: 1;
    }

    &.ant-menu-item-disabled {
      color: #fff;
      opacity: 0.4;
      &:hover {
        background-color: transparent !important;

        & svg {
          fill: #fff;

          &:after {
            content: '';
            background: #e4e8fb;
            border-radius: 50%;
          }
        }

        & a {
          font-weight: 400;
          color: #fff;
        }
      }

      & a {
        color: #fff !important;
      }
    }
  }
`;

const LinkItem = styled(Link)`
  &&& {
    font: normal 400 1.6rem/1.5 Work Sans, sans-serif;
    color: #fff;
  }
`;

interface IMenuItem extends MenuItemProps {
  to: string;
}
export const MenuItem: React.FC<IMenuItem> = ({ to, children, ...rest }: IMenuItem) => {
  const handleRedirect = (location: any) => location;

  return (
    <MenuAntdItem
      key={children?.toString().toLowerCase() || to}
      data-url={to}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <LinkItem to={handleRedirect}>{children}</LinkItem>
    </MenuAntdItem>
  );
};

export const LoadingContainer = styled.div`
  margin-top: 20px;

  display: flex;
  justify-content: center;
`;
