import React from 'react';

const IconLocation: React.FC = () => (
  <svg
    enableBackground="new 0 0 50 50"
    height="20px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 50 50"
    width="20px"
    style={{ marginBottom: '-4px' }}
  >
    <rect fill="none" height="20" width="20" />
    <path
      d="M40,17  c0,13.604-15,31-15,31S10,30.928,10,17c0-8.285,6.715-15,15-15C33.284,2,40,8.715,40,17z"
      fill="none"
      stroke="#ffffff"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="5"
    />
    <circle
      cx="25"
      cy="18"
      fill="none"
      r="6"
      stroke="#ffffff"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="4"
    />
  </svg>
);

export default IconLocation;
