import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import AntdSelect from '../../../../components/Forms/Selects/AntdSelect';
import { useTrackfyApi } from '../../../../hooks/useTrackfyApi';
import IconUsers from '../../../../Icons/Users';
import { useAverageTimeContext } from './context/AverageTimeContext';

const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  & > svg {
    margin-right: 1.6rem;
    margin-top: 0.3rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0.6rem;
`;

/* const Span = styled.span`
  font: normal 500 1.4rem/1 Work Sans, sans-serif;
  color: #4f5882;
`; */

const Filtercontainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 2rem;

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`;

const AverageTimeChartHeader: React.FC = () => {
  const { setAreaSelected } = useAverageTimeContext();
  const { areas, areasState, getAreasApi } = useTrackfyApi();

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    if (mounted) getAreasApi(currentRequest.token);
    return () => {
      mounted = false;
      currentRequest.cancel('getAreasApi request canceled by the user.');
    };
  }, []);

  return (
    <Header>
      <Titlecontainer>
        <IconUsers />
        <div>
          <Title>Tempo médio por colaborador</Title>
          {/* <Span>Média de 4h</Span> */}
        </div>
      </Titlecontainer>
      <Filtercontainer>
        <AntdSelect
          dataRequestState={areasState}
          data={areas}
          handleChange={(e: any) => setAreaSelected(parseInt(e))}
          initialValue="Áreas"
        />
      </Filtercontainer>
    </Header>
  );
};

export default AverageTimeChartHeader;
