import React from 'react';

import { TableStyled, Thead } from './styled';
import TableBody from './TableBody';

export type TableHeader = {
  title: string;
  icon: JSX.Element;
};

type TableProps = {
  tableHeaders: TableHeader[];
  tableData: Array<string[]>;
  loading: boolean;
  isEmpty: boolean;
};

const Table: React.FC<TableProps> = (props: TableProps) => {
  const { tableHeaders, tableData, loading, isEmpty } = props;

  return (
    <TableStyled>
      <Thead>
        <tr>
          {tableHeaders.map((item: any, index: number) => (
            <td key={`${index}-${item.title}`}>
              {item.title} {item.icon}
            </td>
          ))}
        </tr>
      </Thead>
      <TableBody tableData={tableData} loading={loading} isEmpty={isEmpty} />
    </TableStyled>
  );
};

export default Table;
