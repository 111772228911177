import { getConfig } from '../../../config/app.config';
import { ConfigKey } from '../../../enum/ConfigKey';
import * as integration from './integration';
import * as mock from './mock';
import { MenuIcon, RouteKey } from '../../../enum';

const selectedModule = getConfig(ConfigKey.REACT_APP_USE_MOCKS) === 'true' ? mock : integration;

export type UserGroupRoute = {
  key: RouteKey;
  path: string;
};

export type MenuPermission = {
  key: string;
  description: string;
  icon: MenuIcon;
  path: string;
};

export type UserGroupPermissions = {
  rules: string[];
  routes: UserGroupRoute[];
  menu: MenuPermission[];
};

export type GetUserGroupPermissions = (accessToken: string) => Promise<UserGroupPermissions>;

export const getUserGroupPermissions: GetUserGroupPermissions =
  selectedModule.getUserGroupPermissions;

export const getUserGroupPermissions2: GetUserGroupPermissions =
  selectedModule.getUserGroupPermissions3;
