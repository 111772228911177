import React from 'react';
import styled from 'styled-components';

import { Shadow } from '../../components';
import { ContentContainer } from '../../components/PageStructure/styles';
import CollaboratorTimelineLine from './collaboratorTimeline/CollaboratorTimeline';
import CollaboratorTimelineHeader from './collaboratorTimeline/CollaboratorTimelineHeader';
import { TimelineContextProvider } from './collaboratorTimeline/context/CollaboratorTimelineContext';
import { MovimentContextProvider } from './context/MovimentContext';
import Heatmap from './HeatMap/HeatMap';
import Legend from './HeatMap/Legend';
import FullCallendar from './FullCallendar/FullCallendar';
import MovimentOptions from './MovimentOptions';
import './FullCallendar/FullCallendar.css';
import { useAuthContext } from '../../stores/AuthContext';

const HeatMapCard = styled(Shadow)`
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  min-height: 590px;
  max-height: 600px;
  /*padding: 2.4rem 2.4rem 1.6rem 3rem;*/
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const CalendarCard = styled(Shadow)`
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  min-height: 590px;
  max-height: 600px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & .fc-media-screen {
    height: 100%;
  }
`;

const TimelineCard = styled(Shadow)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  //overflow-y: hidden;
  //overflow-x: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

const Moviment: React.FC = () => {
  const { authData } = useAuthContext();
  const companies:string = authData? authData.grpCompanies:'t';
    
  return (
  <ContentContainer value="">
    <MovimentContextProvider>
      <TimelineContextProvider>
        <MovimentOptions />
        {(companies === 'a')?
          <HeatMapCard>
            <Heatmap />
            <Legend />
          </HeatMapCard>
          : <></>
        }
        <CalendarCard>
          <FullCallendar />
        </CalendarCard>
        <TimelineCard>
          <CollaboratorTimelineHeader />
          <CollaboratorTimelineLine />
        </TimelineCard>
      </TimelineContextProvider>
    </MovimentContextProvider>
  </ContentContainer>
)
};

export default Moviment;
