import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IconLoading } from '../../../../../components/IconLoading';
import { iconConfig } from '../../../../../config';
import IconLogOut from '../../../../../Icons/LogOut';
import { MenuPermission } from '../../../../../services/api/userGroupPermissions';
import { useAuthContext, usePermissionsContext } from '../../../../../stores';
import { useLayoutMenuContext } from '../../../context/LayoutMenuContext';
import * as S from './styles';

const { confirm } = Modal;

export type MenuItem = {
  key: string;
  description: string;
  icon: React.ReactElement;
  path: string;
};

export const SideBarMenu: React.FC = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const { setCollapsed } = useLayoutMenuContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const { signOut } = useAuthContext();

  const { permissions, loadingPermissions } = usePermissionsContext();

  useEffect(() => {
    if (!permissions || loadingPermissions) return;

    setMenuItems(
      permissions.menu.map(
        (menu: MenuPermission): MenuItem => ({
          key: menu.key,
          description: menu.description,
          path: menu.path,
          icon: iconConfig[menu.icon],
        })
      )
    );
  }, [permissions, loadingPermissions]);

  const initialMenu = pathname.replace('/', '') || 'monitoramento';

  const handleClick = (...args: any[]) => {
    const url: string = args[0]?.item?.props?.['data-url'] || '/';

    if (url === '/') return;
    history.push(`${url}`);
    setCollapsed(true);
  };

  const showLogoutConfirm = () => {
    confirm({
      title: 'Deseja sair do Trackfy?',
      // icon: <IconLogOut />,
      okText: 'Sair',
      okType: 'primary',
      cancelText: 'Não',
      bodyStyle: {
        font: 'normal 500 2rem/1.5 Work Sans, sans-serif',
        display: 'flex',
        justifyContent: 'center',
      },
      className: 'modal-logout',
      onOk: () => {
        signOut();
        setTimeout(() => history.push('/'), 1000);
      },
    });
  };

  if (loadingPermissions) {
    return (
      <S.LoadingContainer>
        <IconLoading customStyle={{ fontSize: '35px' }} />
      </S.LoadingContainer>
    );
  }

  return (
    <S.Menu defaultSelectedKeys={[initialMenu]} mode="inline" onClick={handleClick}>
      {menuItems.map((item: MenuItem) => (
        <S.MenuItem key={item.key} icon={item.icon} to={item.path}>
          {item.description}
        </S.MenuItem>
      ))}

      <S.MenuItem key="sair" icon={<IconLogOut />} to="/" onClick={showLogoutConfirm}>
        Sair
      </S.MenuItem>

      {/*       <SubMenu key="infraestrutura" icon={<IconMap />} title="Infraestrutura">
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 1
        </MenuItem>
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 2
        </MenuItem>
      </SubMenu> */}

      {/*       <SubMenu key="cadastros" icon={<IconUsersMenu />} title="Cadastros">
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 1
        </MenuItem>
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 2
        </MenuItem>
      </SubMenu> */}

      {/*       <SubMenu key="gerenciamento" icon={<IconManager />} title="Gerenciamento">
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 1
        </MenuItem>
        <MenuItem icon={<IconManager />} to="/relatorios">
          Opção 2
        </MenuItem>
      </SubMenu> */}
    </S.Menu>
  );
};
