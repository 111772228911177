import moment from 'moment';
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

type ConformityContextProps = {
  companySelected: number;
  professionSelected: number;
  areaSelected: number;
  initialDate: any;
  finalDate: any;
  setCompanySelected: (companie: number) => void;
  setProfessionSelected: (profession: number) => void;
  setAreaSelected: (area: number) => void;
  updatePeriod: (initial: any, final: any) => void;
};

type Props = {
  children: ReactNode;
};

const ConformityContext = createContext<ConformityContextProps>({} as ConformityContextProps);

export function ConformityContextProvider(props: Props) {
  const { children } = props;
  
  const {authData} = useAuthContext();
  const [initialDate, setInitialDate] = useState<any>(moment());
  const [finalDate, setFinalDate] = useState<any>(moment());
  const [areaSelected, setAreaSelected] = useState<number>(0);
  const [professionSelected, setProfessionSelected] = useState<number>(0);

  const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId:0;
  
  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    areaSelected,
    companySelected,
    professionSelected,
    initialDate,
    finalDate,
    setAreaSelected,
    setCompanySelected,
    setProfessionSelected,
    updatePeriod,
  };
  return <ConformityContext.Provider value={value}>{children}</ConformityContext.Provider>;
}

export function useConformityContext() {
  const context = useContext(ConformityContext);

  if (typeof context === 'undefined') {
    throw new Error('ConformityContext must be used within an ConformityContext');
  }

  return context;
}
