import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MonitoredAreasData, MapAreasData } from '../../monitoring/types/Monitoring';

type AreasContextData = {
  monitoredAreas: MonitoredAreasData[];
  mapAreas: MapAreasData[];
  updateMonitoredAreas: (areas: MonitoredAreasData[]) => void;
  updateMapAreas: (areas: MapAreasData[]) => void;
};

const AreasContext = createContext({} as AreasContextData);

type AreasProviderProps = {
  children: ReactNode;
};

export function AreasContextProvider({ children }: AreasProviderProps) {
  const mounted = useRef(false);
  const [mapAreas, setMapAreas] = useState<MapAreasData[]>([]);
  const [monitoredAreas, setMonitoredAreas] = useState<MonitoredAreasData[]>(
    []
  ); /* Estado dos dados brutos das áreas monitoradas */

  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const updateMonitoredAreas = useCallback(
    (areas: MonitoredAreasData[]) => {
      if (mounted.current) {
        setMonitoredAreas(areas);
      }
    },
    [setMonitoredAreas]
  );

  const updateMapAreas = useCallback(
    (areas: MapAreasData[]) => {
      if (mounted.current) setMapAreas(areas);
    },
    [setMonitoredAreas]
  );

  const value = {
    monitoredAreas,
    mapAreas,
    updateMonitoredAreas,
    updateMapAreas,
  };

  return <AreasContext.Provider value={value}>{children}</AreasContext.Provider>;
}

export function useAreasContext() {
  const context = useContext(AreasContext);

  if (typeof context === 'undefined') {
    throw new Error('AreasContext must be used within an useAreasContext');
  }

  return context;
}
