import React from 'react';
import styled from 'styled-components';

import { Shadow } from '../../components';
import { ContentContainer } from '../../components/PageStructure/styles';
import BurndownChart from './BurnDownChart/BurndownChart';
import BurndownChartHeader from './BurnDownChart/BurndownChartHeader';
import ConformityOptions from './ConformityOptions';
import { ConformityContextProvider } from './context/ConformityContext';
import { TimelineContextProvider } from './Timeline/context/TimelineContext';
import Timeline from './Timeline/Timeline';
import { TimelineHeader } from './Timeline/TimelineHeader';

const TimelineCard = styled(Shadow)`
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

const BurnoutCard = styled(Shadow)`
  //display: grid;
  //grid-template-columns: 1fr;
  //grid-template-rows: max-content auto;
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 2.4rem 2.4rem 1.6rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }
`;

const PageConformity: React.FC = () => (
  <ContentContainer value="">
    <ConformityContextProvider>
      <ConformityOptions />
      <BurnoutCard>
        <BurndownChartHeader />
        <BurndownChart />
      </BurnoutCard>
      <TimelineCard>
        <TimelineContextProvider>
          <TimelineHeader />
          <Timeline />
        </TimelineContextProvider>
      </TimelineCard>
    </ConformityContextProvider>
  </ContentContainer>
);

export default PageConformity;
