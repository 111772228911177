import React from 'react';

import { TableStyled, Thead } from '../../../components/Table/styled';
import MachinesTableBody from './MachinesTableBody';

export type MachinesTableHeader = {
  title: string;
};

type MachinesTableProps = {
  tableHeaders: MachinesTableHeader[];
  tableData: Array<string[]>;
  loading: boolean;
  isEmpty: boolean;
  idValues: string[];
  currentStatus: string[];
  setCurrentStatus: (value: string[]) => void;
};

const MachinesTable: React.FC<MachinesTableProps> = (props: MachinesTableProps) => {
  const { tableHeaders, tableData, loading, isEmpty, idValues, currentStatus, setCurrentStatus } =
    props;

  return (
    <TableStyled>
      <Thead>
        <tr>
          {tableHeaders.map((item: any, index: number) => (
            <td key={`${index}-${item.title}`}>
              {item.title} {item.icon}
            </td>
          ))}
        </tr>
      </Thead>
      <MachinesTableBody
        tableData={tableData}
        loading={loading}
        isEmpty={isEmpty}
        idValues={idValues}
        setCurrentStatus={setCurrentStatus}
        currentStatus={currentStatus}
      />
    </TableStyled>
  );
};

export default MachinesTable;