import React from 'react';
import styled from 'styled-components';

import { IconLoading } from '../../../../components/IconLoading';
import ChartDown from '../../../../Icons/ChartDown';

type PropsTypes = {
  value: any;
  isLoadingAverage: boolean;
  percentage?: boolean;
};

const Container = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  gap: 9px;
  margin-bottom: 2rem;
`;

const Value = styled.div`
  font: normal 600 4rem/1.5 Work Sans, sans-serif;
  color: #4f5882;

  @media (max-width: 576px) {
    &&& {
      font-size: 3.5rem;
    }
  }
`;

const RankChartLabel: React.FC<PropsTypes> = ({
  value = 0,
  isLoadingAverage = true,
  percentage = false,
}: PropsTypes) => {
  if (isLoadingAverage) return <IconLoading />;
  return (
    <Container>
      <Value>
        {value}
        {`${percentage ? '%' : ''}`}
      </Value>
      <ChartDown />
    </Container>
  );
};

export default RankChartLabel;
