import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { mixpanelInitialize } from '../services/SystemMonitor';

import { useAuth, useIsMounted } from '../hooks';
import api from '../services/api';
import { IAuthRequest } from '../services/api/auth';
import { OverridedMixpanel } from 'mixpanel-browser';

type AuthData = {
  isAdmin?: boolean;
  access_token: string;
  refresh_token: string;
  username: string;
  email: string;
  persona?: string;
  clientId: number;
  companyId:number;
  grpCompanies:string;
} | null;

type AuthContextData = {
  authData: AuthData | undefined;
  signIn: (params: IAuthRequest) => Promise<void>;
  signOut: () => void;
  monitorAgent: OverridedMixpanel|undefined;
};

const AuthContext = createContext({} as AuthContextData);

type AuthContextProviderProps = {
  children: ReactNode;
};

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [authData, setAuthData] = useState<AuthData>(() => {
    const auth = localStorage.getItem('authData');

    if (!auth) return null;

    const jsonResponse: any = JSON.parse(auth) as AuthData;
    
    if (!jsonResponse) return null;

    const parsedAuth:AuthData = {
      isAdmin: jsonResponse.isAdmin,
      access_token: jsonResponse.access_token,
      refresh_token: jsonResponse.refresh_token,
      username: jsonResponse.profileData.name,
      email: jsonResponse.profileData.email,
      persona: jsonResponse.profileData.persona,
      clientId: jsonResponse.profileData.clientId,
      companyId:jsonResponse.profileData.companyId,
      grpCompanies:jsonResponse.profileData.grpCompanies
    };

    api.defaults.headers.common.Authorization = `Bearer ${parsedAuth.access_token}`;

    return parsedAuth;
  });

  const { sigInProvider, signOutProvider } = useAuth();

  const isMounted = useIsMounted();

  const signIn = useCallback(
    async (params: IAuthRequest) => {
      const response = await sigInProvider(params);

      if (isMounted.current) setAuthData(response);
    },
    [authData, isMounted]
  );

  const signOut = useCallback(() => {
    signOutProvider();

    if (isMounted.current) setAuthData(null);
  }, [authData, isMounted]);

  let monitorAgent:OverridedMixpanel|undefined =  undefined;

  if (process.env.NODE_ENV === 'production'){ 
    const mixpanelToken:string = process.env.REACT_APP_MIXPANEL_TOKEN || '';
    
    if(mixpanelToken.length > 0)
      monitorAgent = mixpanelInitialize(mixpanelToken, false);
  }

  const value = {
    authData,
    signIn,
    signOut,
    monitorAgent
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error('AuthContext must be used within an useAuthContext');
  }

  return context;
}
