import styled from 'styled-components';

export const Ul = styled.ul`
  width: 98%;

  & li {
    border-bottom: 1px solid #5669c8;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  & li:hover {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  & > li > div {
    height: 50px;
    color: black;
    padding: 0 10px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4f5882;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > li > div > *:first-child {
    font-size: 16px;
  }

  & > li > div > button:hover {
    transform: scale(1.2);
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 400px;
  //margin-top: 30px;

  background: #ffffff;
  border: 0.5px solid #ebebf9;
  box-sizing: border-box;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13);
  border-radius: 10px;

  display: flex;
  justify-content: center;

  & .input[type='date' i]::-webkit-calendar-picker-indicator {
    background-image: none;
  }
`;

export const GenerateReport = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
