import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAuthContext } from '.';
import { useAuth, useIsMounted } from '../hooks';
import {
  getUserGroupPermissions,
  getUserGroupPermissions2,
  UserGroupPermissions,
} from '../services/api/userGroupPermissions';

type PermissionsContextData = {
  permissions: UserGroupPermissions | undefined;
  loadingPermissions: boolean;
  updatePermissions: (permissions: UserGroupPermissions) => void;
};

const PermissionsContext = createContext({} as PermissionsContextData);

type PermissionsContextProviderProps = {
  children: ReactNode;
};

export function PermissionsContextProvider({ children }: PermissionsContextProviderProps) {
  const [permissions, setPermissions] = useState<UserGroupPermissions>();
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(true);

  const isMounted = useIsMounted();
  const { authData } = useAuthContext();
  const { getToken } = useAuth();

  const accessToken = getToken();

  const updatePermissions = useCallback(
    (permissions: UserGroupPermissions) => {
      if (isMounted.current) setPermissions(permissions);
    },
    [permissions]
  );

  useEffect(() => {
    if (!accessToken || !authData) return;

    getUserGroupPermissions2(accessToken).then((userGroupPermissions: UserGroupPermissions) => {
      //console.log(userGroupPermissions);
      if (isMounted.current) setLoadingPermissions(false);
      updatePermissions(userGroupPermissions);
    });
  }, [accessToken, authData]);

  const value = {
    permissions,
    loadingPermissions,
    updatePermissions,
  };

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}

export function usePermissionsContext() {
  const context = useContext(PermissionsContext);

  if (typeof context === 'undefined') {
    throw new Error('PermissionsContext must be used within an usePermissionsContext');
  }

  return context;
}
