import { CancelToken } from 'axios';

import { getConfig } from '../../../config/app.config';
import { Timeline } from '../../../containers/indicators/types/Indicators';
import { MachinesSummaryData } from '../../../containers/machinesSummary/types/MachinesSummaryTypes';
import { IHeatmapArea } from '../../../containers/moviment/HeatMap/types/HeatMapData';
import { ConfigKey } from '../../../enum/ConfigKey';
import { Company } from '../../../types/Filters';
import * as integration from './integration';
import * as mock from './mock';

const selectedModule = getConfig(ConfigKey.REACT_APP_USE_MOCKS) === 'true' ? mock : integration;

export type TimelineData = {
  companyId: number;
  professionId: number;
  areaId: number;
  initialDate: number;
  finalDate: number;
  currentDate: number;
  cancelToken: CancelToken;
};


export type GetTimelineData = (data: TimelineData) => Promise<Timeline[]>;

export type GetCompanies = (operational:boolean, cancelToken?: CancelToken) => Promise<Company[]>;

export type GetMachinesSummary = (cancelToken?: CancelToken) => Promise<MachinesSummaryData>;

export type PutMachineStatus = (machineCod: string, status: string, cancelToken?: CancelToken) => Promise<void>;

export type GetHeatMapData = (companyId: number, professionId: number, initialDate: number, finalDate: number, 
  cancelToken: CancelToken) => Promise<IHeatmapArea[]>;

export const getTimelineData: GetTimelineData = selectedModule.getTimelineData;
export const getCompanies: GetCompanies = selectedModule.getCompanies;
export const getMachinesSummary: GetMachinesSummary = selectedModule.getMachinesSummary;
export const putMachineStatus: PutMachineStatus = selectedModule.putMachineStatus;
export const getHeatMapData: GetHeatMapData = selectedModule.getHeatMapData;