import React from 'react';

import { MenuIcon } from '../enum';
import IconPdf from '../Icons/IconPdf';
import IconChartBars from '../Icons/ChartBars';
import IconDashboard from '../Icons/Dashboard';
import IconCalendar from '../Icons/Calendar';
import IconForkLift from '../Icons/ForkLift';

export const iconConfig = {
  [MenuIcon.dashboard]: <IconDashboard />,
  [MenuIcon.indicators]: <IconChartBars />,
  [MenuIcon.reports]: <IconPdf />,
  [MenuIcon.summary]: <IconCalendar />,
  [MenuIcon.machinesSummary]: <IconForkLift />,
};
