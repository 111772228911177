import React from 'react';

import { PageHeaderProps } from './types';
import * as S from './styles';

export const PageHeader: React.FC<PageHeaderProps> = ({ title }: PageHeaderProps) => (
  <S.HeaderContainer>
    <S.Title>{title}</S.Title>
  </S.HeaderContainer>
);
