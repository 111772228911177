import React from 'react';
import styled from 'styled-components';

import IconUsers from '../../../Icons/Users';
import { useTimelineContext } from './context/TimelineContext';
import { useTimeline } from './hooks/useTimeline';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Headercontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    margin-right: 1.6rem;
  }
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1.6rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const Date = styled.span`
  background-color: #4f5882;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 0px 8px;
`;

export const TimelineHeader: React.FC = () => {
  const { timelineData } = useTimelineContext();
  const { formatTimelineDate } = useTimeline();

  return (
    <Header>
      <Headercontainer>
        <Titlecontainer>
          <IconUsers />
          <Title>Linha do Tempo de presença</Title>
          <Date>{formatTimelineDate(timelineData)}</Date>
        </Titlecontainer>
      </Headercontainer>
    </Header>
  );
};
