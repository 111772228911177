// import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

// import IconInfo from '../../../../../Icons/Info';
import IconUsers from '../../../../Icons/Users';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Titlecontainer = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 1.6rem;
  }
`;

const Title = styled.h3`
  font: normal 600 1.7rem/1 Work Sans, sans-serif;
  color: #4f5882;
  margin-bottom: 0;
`;

const PeopleInAreasChartHeader: React.FC = () => (
  <Header>
    <Titlecontainer>
      <IconUsers />
      <Title>Pessoas por áreas</Title>
    </Titlecontainer>
    {/*     <Tooltip title="Tooltip" color="blue" overlayClassName="people-charts-tooltip">
      <span>
        <IconInfo />
      </span>
    </Tooltip> */}
  </Header>
);

export default PeopleInAreasChartHeader;
