/* eslint camelcase: ["error", {allow: ["access_token", "refresh_token", "full_name"]}] */
import { Col, Row } from 'antd';
import React from 'react';

import Logo from '../../assets/brand/LogoNormal';
import { IconLoading } from '../../components/IconLoading';
import { useLogin } from './hooks/useLogin';
import * as S from './styles';

export const LoginContainer: React.FC = () => {
  const { user, password, loading, message, typeMessages, setUser, setPassword, handleSignIn } =
    useLogin();

  return (
    <Row>
      <Col xs={0} md={12} lg={13}>
        <S.Background>
          <S.TextTitle>
            <b>Revolucionando</b> a gestão e segurança <br />
            de indústrias com <b>IOT.</b>
          </S.TextTitle>
        </S.Background>
      </Col>
      <Col xs={24} md={12} lg={11}>
        <S.LoginContainer>
          <S.LogoContainer>
            <Logo />
          </S.LogoContainer>
          <S.LoginForm onSubmit={handleSignIn}>
            <S.FormControl>
              <S.Label>
                Usuário
                <S.Input
                  autoFocus
                  value={user}
                  placeholder="Digite seu usuário"
                  onChange={(e) => setUser(e.target.value)}
                />
              </S.Label>
            </S.FormControl>
            <S.FormControl>
              <S.Label>
                Senha
                <S.Input
                  type="password"
                  value={password}
                  placeholder="Digite sua senha"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </S.Label>
            </S.FormControl>
            <S.FormControl>
              {loading && (
                <S.IconLoadingContainer>
                  <IconLoading customStyle={{ fontSize: 24 }} />
                </S.IconLoadingContainer>
              )}
              {message && (
                <S.Message type={message}>
                  {typeMessages[message].icon}
                  {typeMessages[message].message}
                </S.Message>
              )}
              {!loading && !message && <S.Space />}
            </S.FormControl>
            <S.FormControl>
              <S.Submit type="submit" disabled={loading}>
                Entrar
              </S.Submit>
            </S.FormControl>
          </S.LoginForm>
          <S.LoginFooter>
            <S.Text>2021 - Trackfy</S.Text>
            <S.Text>V1.09454</S.Text>
          </S.LoginFooter>
        </S.LoginContainer>
      </Col>
    </Row>
  );
};
