import { useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import ReactMapboxGl from 'react-mapbox-gl';

import { useAuth, useIsMounted } from '../../../hooks';
import cookieService from '../../../services/CookieService';
import { fetchData } from '../../../services/requests';
import { GeolocationPosition } from '../../../types/Postion';

type CoordinatesResponse = {
  id: number;
  latitude: string;
  longitude: string;
};

export const useMap = () => {
  const baseUrl: string | undefined = process.env.REACT_APP_SERVER_HOST;
  const token: any = cookieService.get('token');
  const { CLIENT_ID } = useAuth();

  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);
  const [initializeMap, setInitializeMap] = useState<boolean>(false);
  const currentRequest: CancelTokenSource = Axios.CancelToken.source();
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [satelite, setSatelite] = useState<boolean>(false);

  const isMounted = useIsMounted();

  const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
    //minZoom: 10,
    //maxZoom: 19,
    minZoom: 17,
    maxZoom: 17,
    scrollZoom: false,
    attributionControl: false,
    doubleClickZoom: false,
  });

  const mapGoToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
      if (initializeMap) return;

      const latitude: number = Number(position.coords.latitude);
      const longitude: number = Number(position.coords.longitude);

      if (initializeMap) return;

      if (!isMounted.current) return;

      setLat(latitude);
      setLong(longitude);
      setInitializeMap(true);
    });
  };

  useEffect(() => {
    const clientId: string | null = localStorage.getItem(CLIENT_ID)
      ? localStorage.getItem(CLIENT_ID)
      : '2';

    fetchData(token, `${baseUrl}/monitoring/coordinates`, { clientId }, currentRequest.token)
      .then((response: AxiosResponse) => {
        const { latitude, longitude } = response.data as CoordinatesResponse;

        if (!latitude || !longitude) return mapGoToCurrentLocation();

        if (initializeMap) return;

        if (!isMounted.current) return;

        setLat(Number(latitude));
        setLong(Number(longitude));
        setInitializeMap(true);
      })
      .catch((_error) => {
        if (!isMounted.current) return;
        mapGoToCurrentLocation();
      });
  }, [mapStyle]);

  const handleChange = () => {
    if (mapStyle === 'mapbox://styles/mapbox/streets-v11') {
      setMapStyle('mapbox://styles/mapbox/satellite-streets-v11');
      setSatelite(true);
    } else {
      setMapStyle('mapbox://styles/mapbox/streets-v11');
      setSatelite(false);
    }
  };

  return {
    lat,
    long,
    satelite,
    initializeMap,
    mapStyle,
    handleChange,
    Map,
  };
};
