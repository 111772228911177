import React from 'react';

import { PageContent, PageOptions } from './components';
import { Page } from '../../components/PageStructure/Page';
import { ReportsContextProvider } from './context/ReportsContext';
import {ContentContainer} from '../../components/PageStructure/styles';

export const ReportsContainer: React.FC = () => (
  <ReportsContextProvider>
      <Page title="Relatórios" 
      components={
        <ContentContainer value="">
          <PageOptions />
          <PageContent />
        </ContentContainer>
      } />
  </ReportsContextProvider>
);
