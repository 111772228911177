import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import { useEffect } from 'react';

import { useTrackfyApi } from '../../../../../hooks';
import { useReportsContext } from '../../../context/ReportsContext';

export const usePageOptions = () => {
  const {
    getCompaniesApi,
    getCollaboratorsApi,
    getProfessionsApi,
    companies,
    professions,
    companiesState,
    professionsState,
  } = useTrackfyApi();
  const {
    companySelected,
    professionSelected,
    updatePeriod,
    setCollaborators,
    setCollaboratorsState,
    setCompanySelected,
    setProfessionSelected,
    setCollaboratorSelected,
    collaborators,
    collaboratorsState,
  } = useReportsContext();

  useEffect(() => {
    updatePeriod(moment(), moment());
  }, []);

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    getCompaniesApi(currentRequest.token);
    return () => currentRequest.cancel('getCompaniesApi request canceled by the user.');
  }, []);

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    getProfessionsApi(currentRequest.token);
    return () => currentRequest.cancel('getProfessionsApi request canceled by the user.');
  }, []);

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    getCollaboratorsApi(
      companySelected,
      professionSelected,
      currentRequest.token,
      setCollaborators,
      setCollaboratorsState
    );
    return () => currentRequest.cancel('getCollaboratorsApi request canceled by the user.');
  }, [companySelected, professionSelected]);

  return {
    companies,
    professions,
    companiesState,
    professionsState,
    setCompanySelected,
    setProfessionSelected,
    setCollaboratorSelected,
    updatePeriod,
    collaborators,
    collaboratorsState,
  };
};
