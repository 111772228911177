import { useState } from 'react';

import { RankByProfessionData } from '../../../../indicators/types/Indicators';

export const useRank = () => {
  const [rankChartData, setRankChartData] = useState<RankByProfessionData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getColor = (value: number) => {
    if (value < 45) return '#e45a5a';
    if (value < 70) return '#2f4cdd';
    return '#86d655';
  };

  return {
    rankChartData,
    isLoading,
    getColor,
    setRankChartData,
    setIsLoading,
  };
};
