import React from 'react';

import { DateRangePicker } from '../../../../components';
import AntdSelect from '../../../../components/Forms/Selects/AntdSelect';
import { usePageOptions } from './hooks/usePageOptions';
import * as S from '../../../../components/PageStructure/styles';
import { useAuthContext } from '../../../../stores/AuthContext';

export const PageOptions: React.FC = () => {
  const {
    setCompanySelected,
    setProfessionSelected,
    setCollaboratorSelected,
    updatePeriod,
    collaborators,
    collaboratorsState,
    companies,
    professions,
    companiesState,
    professionsState,
  } = usePageOptions();
  const {authData} = useAuthContext();
  const compIds:string = authData? authData.grpCompanies:'t';

  return (
    <S.OptionsContainer>
      {((compIds!="a") && (!compIds.includes(",")))?
        <></>:
        <div>
          <AntdSelect
            dataRequestState={companiesState}
            data={companies}
            handleChange={(e: any) => setCompanySelected(parseInt(e))}
            initialValue="Empresas"
          />
        </div>
      }
      <div>
        <AntdSelect
          dataRequestState={professionsState}
          data={professions}
          handleChange={(e: any) => setProfessionSelected(parseInt(e))}
          initialValue="Funções"
        />
      </div>
      <div>
        <AntdSelect
          dataRequestState={collaboratorsState}
          data={collaborators}
          handleChange={(e: any) => setCollaboratorSelected(parseInt(e))}
          initialValue="Colaboradores"
        />
      </div>
      <div>
        <DateRangePicker
          updatePeriod={updatePeriod}
          screen="Relatórios"
          maxDate={new Date()}
          component=""
          selectRange
          showToday
          loadDate={new Date()}
        />
      </div>
    </S.OptionsContainer>
  );
};
