import React from 'react';

const IconForkLift: React.FC = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 66.000000 66.000000"
    preserveAspectRatio="xMidYMid meet"
    fill=""
  >
    <g
      transform="translate(0.000000,66.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M539 593 c-55 -37 -249 -226 -249 -242 0 -10 -9 -26 -20 -36 -14 -13
-20 -31 -20 -62 0 -32 -4 -43 -15 -43 -12 0 -15 18 -15 104 0 77 -4 107 -14
115 -9 7 -40 11 -78 9 l-63 -3 -27 -80 c-41 -121 -37 -255 8 -255 9 0 29 -11
46 -25 37 -31 43 -31 74 0 22 22 33 25 101 25 66 0 79 -3 105 -25 37 -31 43
-31 74 0 30 30 37 31 55 6 27 -37 55 -35 99 7 37 34 41 43 38 78 l-3 39 -127
3 -128 3 0 47 c1 45 4 50 90 142 l89 95 1 -50 c0 -32 7 -61 19 -79 20 -30 18
-56 -5 -56 -7 0 -18 9 -24 20 -14 26 -30 26 -30 0 0 -11 11 -27 25 -36 23 -15
27 -15 50 0 29 19 32 51 8 78 -21 23 -31 144 -14 161 17 17 13 55 -7 66 -14 7
-25 6 -43 -6z m33 -28 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11
13 6 -2 11 -8 11 -13z m-34 -40 c-2 -7 -42 -56 -89 -107 -77 -85 -88 -93 -108
-85 -12 6 -21 16 -19 22 5 18 202 194 211 188 4 -3 7 -11 5 -18z m-340 -257
c1 -76 0 -138 -3 -138 -3 0 -14 9 -25 20 -11 11 -29 20 -40 20 -11 0 -29 -9
-40 -20 -11 -11 -27 -20 -35 -20 -24 0 -20 128 7 212 l22 69 56 -3 55 -3 3
-137z m152 23 c5 -11 10 -33 10 -50 0 -29 -2 -31 -40 -31 l-40 0 0 44 c0 26 5
46 13 49 26 11 47 6 57 -12z m270 -136 c0 -30 -4 -31 -30 -5 -11 11 -29 20
-40 20 -11 0 -29 -9 -40 -20 -25 -25 -42 -25 -58 0 -8 13 -23 20 -42 20 -19 0
-34 -7 -42 -20 -11 -17 -24 -20 -80 -20 -66 0 -68 1 -68 25 l0 25 200 0 200 0
0 -25z m-462 -39 c5 -25 -12 -39 -37 -31 -20 6 -28 34 -14 49 13 13 48 1 51
-18z m280 0 c5 -25 -12 -39 -37 -31 -20 6 -28 34 -14 49 13 13 48 1 51 -18z
m138 9 c7 -18 -13 -45 -33 -45 -17 0 -27 24 -19 45 7 20 45 19 52 0z"
      />
      <path
        d="M85 330 c-22 -71 -15 -83 41 -78 l39 3 3 68 3 67 -34 0 c-33 0 -34
-2 -52 -60z m63 -11 c-2 -26 -8 -35 -25 -37 -27 -4 -28 6 -8 55 19 44 38 33
33 -18z"
      />
      <path
        d="M130 220 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0
-20 -4 -20 -10z"
      />
      <path
        d="M300 270 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"
      />
    </g>
  </svg>
);

export default IconForkLift;