import React from 'react';
import react from 'react';
import styled from 'styled-components';

export const Yellow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: yellow;
  height: 100%;
  width: 33.33%;
`;

export const Orange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: orange;
  height: 100%;
  width: 33.33%;
`;

export const Red = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  height: 100%;
  width: 33.33%;
`;

const Legend = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'stetrch',
        height: '20px',
      }}
    >
      <Yellow>1-2 HH</Yellow>
      <Orange>2-6 HH</Orange>
      <Red>6+ HH</Red>
    </div>
  );
};

export default Legend;
