import { Collapse } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import ScrollBar, { positionValues } from 'react-custom-scrollbars';
import useSwr from 'swr';
import Axios, { CancelTokenSource }  from 'axios';

import { useAuth } from '../../../../../hooks';
import { ListHeader, ListItem } from '.';
import { MonitoredAreasData, OccupationData } from '../../../../monitoring/types/Monitoring';
import { useAreasContext } from '../../../../map/context/AreasContext';
import { useTrackfyApi } from '../../../../../hooks';
import { useCompanyFilterContext } from '../../../../../stores/CompanyFilterContext';
import { IconLoading } from '../../../../../components/IconLoading';

const { Panel } = Collapse;

export const CardContent: React.FC = () => {
  const { CLIENT_ID } = useAuth();
  const {generateMonitoredAreasApi} = useTrackfyApi();
  const [mounted, setMounted] = useState<boolean>(false);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const { monitoredAreas, updateMonitoredAreas } = useAreasContext();
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);
  const scrollPositionTop: React.MutableRefObject<number> = useRef<number>(0);
  const { selectedCompany, areasLoading, setAreasLoading } = useCompanyFilterContext();
  const companyId = selectedCompany?.id ? selectedCompany?.id:'0';
 
  // Mantém os dados de monitoramento das áreas sincronizados
  useSwr(
    mounted ? "monitoring/areas" : null,
    async () => { 
      //const clientId = localStorage.getItem(CLIENT_ID) ? localStorage.getItem(CLIENT_ID) : '2';
      //if(null != clientId){
        generateMonitoredAreasApi(//Number.parseInt(clientId), 
                                  Number.parseInt(companyId), 
                                  Axios.CancelToken.source().token, 
                                  updateMonitoredAreas, 
                                  setAreasLoading);
      //}
    },{
      focusThrottleInterval: 180001,
      refreshInterval: 180000,
    }
  );
  
  useEffect(() => {
    const scrollTop = () => ScrollbarsRef.current?.scrollTop(scrollPositionTop.current);
    if (scrollPositionTop.current) scrollTop();
  }, [scrollPositionTop]);
  
  useEffect(() => {
    setMounted(true);
    //const clientId = localStorage.getItem(CLIENT_ID) ? localStorage.getItem(CLIENT_ID) : '2';
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    //if(null != clientId){
      generateMonitoredAreasApi(//Number.parseInt(clientId), 
                                Number.parseInt(companyId), 
                                currentRequest.token, 
                                updateMonitoredAreas, 
                                setAreasLoading);
    //}
    return () => {
      setMounted(false);
      currentRequest.cancel('DailySummary request canceled by the user.');
    }
  }, [companyId]);

  if (!monitoredAreas) {
    return <p style={{ color: '#000', textAlign: 'center' }}>Carregando dados...</p>;
  }

  const ContainerAreaMonitored = () => {
    if (monitoredAreas.length > 0) {
      setTimeout(() => ScrollbarsRef.current?.forceUpdate(), 500);
    }

    const handleScrollFrame = (values: positionValues) => {
      scrollPositionTop.current = values.scrollTop;
    };
//customStyle={{marginLeft:"150px", height:"50px", width:"50px"}}
    return (
      <div className="content">
        <ScrollBar ref={ScrollbarsRef} onScrollFrame={handleScrollFrame}>
          {areasLoading ? (
            <div style={{marginLeft:"150px", marginTop:"15px"}}>
              <IconLoading />
            </div>
          ) : (
            <Collapse
              className="list-monitor"
              expandIconPosition="right"
              activeKey={activeKeys}
              onChange={(e: any) => {
                setActiveKeys(e);
                setTimeout(() => ScrollbarsRef.current && ScrollbarsRef.current.forceUpdate(), 250);
              }}
            >
              {monitoredAreas.map((area: MonitoredAreasData) => {
                const quantity: number =
                  area.data.length === 0
                    ? 0
                    : area.data.reduce(
                        (previousValue: any, currentValue: OccupationData) =>
                          previousValue + currentValue.quantity,
                        0
                      );

                return (
                  <Panel
                    key={area.coordenadas}
                    className="area-monitored"
                    header={<ListHeader title={area.nome} quantity={quantity} color={area.cor} />}
                  >
                    <ListItem data={area.data} />
                  </Panel>
                );
              })}
            </Collapse>
          )}
        </ScrollBar>
      </div>
    );
  };

  return <ContainerAreaMonitored />;
};

/*async (url: string) => {
  setAreasLoading(true);
  setFinished(false);
  const clientId = localStorage.getItem(CLIENT_ID) ? localStorage.getItem(CLIENT_ID) : '2';
  
  await fetchData(token, url, { clientId })
  .then((res: AxiosResponse<any>) => {
    if (finished) 
      return;
            
    if (!res.data || res.data.length === 0) {
      console.log(`res.data.length: ${res.data.length}`);
      return;
    }
  
    if (!isMounted.current)
      return;
     
    setAreasLoading(false);
    updateMonitoredAreas(res.data);
  })
  // eslint-disable-next-line no-console
  .catch((error: any) => console.error(error));
},*/

/*useEffect(() => {
  setMounted(true);
  return () => { setMounted(false); }
}, [monitoredAreas]);*/