import moment from 'moment';
import React from 'react';

import IconClose from '../../../../../Icons/Close';
import IconDot from '../../../../../Icons/Dot';

type Props = {
  title: string;
  currentLocation: {
    area: string;
    color: string;
    time: number;
  };
  onClickToClose: () => any;
};

export const HistoryHeader: React.FC<Props> = (props: Props) => {
  const { title, currentLocation, onClickToClose } = props;
  const { area, color, time } = currentLocation;

  const hour: number = moment.duration(time).hours();
  const minute: number = moment.duration(time).minutes();
  const second: number = moment.duration(time).seconds();

  let txtTime: string = '';
  if (hour) {
    txtTime = hour.toString();
  } else if (minute) {
    txtTime += hour ? `:${minute.toString()}` : minute.toString();
  } else if (second) {
    txtTime += minute ? `:${second.toString()}` : second.toString();
  }

  let txtLabel: string = '';
  if (hour) {
    txtLabel = 'h';
  } else if (minute) {
    txtLabel = 'min';
  } else if (second) {
    txtLabel = 's';
  }

  return (
    <div className="history-header">
      <div className="title">
        <span style={{ width: '100%' }}>{title}</span>
        <div className="icon-close" onClick={onClickToClose}>
          <IconClose />
        </div>
      </div>
      <div className="descrition">está atualmente em</div>
      <div className="resume">
        <div className="resume-location">
          <IconDot fill={color} />
          <span>{area}</span>
          <span>
            (há {txtTime}
            {txtLabel})
          </span>
        </div>
      </div>
    </div>
  );
};
