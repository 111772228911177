// import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';
import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, ResponsiveContainer, LabelList } from 'recharts';

import { IconLoading } from '../../../../components/IconLoading';
import { useTrackfyApi } from '../../../../hooks/useTrackfyApi';
import { useProductivityContext } from '../../context/PageProductivityContext';
import { useAverageTimeContext } from './context/AverageTimeContext';
import { useAverageTime } from './hooks/useAverageTime';

const AverageTimeChart: React.FC = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const {
    areaSelected,
    collaboratorsSelctValue,
    setAverageValues,
    averageTimeChartData,
    setAverageTimeChartData,
    isLoading,
    setIsLoading,
  } = useAverageTimeContext();
  const { getAverageTimeChartDataApi } = useTrackfyApi();
  const { dividerPerFive } = useAverageTime();

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getAverageTimeChartDataApi(
        areaSelected,
        collaboratorsSelctValue,
        companySelected,
        professionSelected,
        initialDate,
        finalDate,
        currentRequest.token,
        setIsLoading,
        setAverageTimeChartData,
        dividerPerFive,
        setAverageValues
      );

    return () => {
      mounted = false;
      currentRequest.cancel('AverageTimeChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate, areaSelected]);

  if (isLoading) return <IconLoading />;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={700} height={250} data={averageTimeChartData}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        {/* <YAxis label={{ value: 'Qntd. de horas', angle: -90, position: 'insideLeft' }} /> */}
        <Bar dataKey="value" fill="rgba(217, 209, 255, 100)" radius={[5, 5, 0, 0]} barSize={40}>
          <LabelList dataKey="label" position="insideBottom" offset={30} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AverageTimeChart;

/* useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted: boolean = true;

    if (
      projectSelctValue &&
      typeof companiesSelctValue !== typeof undefined &&
      typeof professionsSelctValue !== typeof undefined &&
      typeof areasSelctValue !== typeof undefined &&
      typeof collaboratorsSelctValue !== typeof undefined
    ) {
      getAverageTimeChartData(currentRequest.token, projectSelctValue)
        .then((response: AxiosResponse<any>) => {
          if (mounted) {
            if (!response.data || response.data.length===0) setAverageTimeChartData([]);
            else {
              const dividerPerFive = (
                base: AverageTimeByPeople[]
              ): Array<AverageTimeByPeople[]> => {
                const divisor: number = 5;
                const newArray: Array<AverageTimeByPeople[]> = [];

                for (let i = 0; i < base.length; i += divisor) {
                  const divider: AverageTimeByPeople[] = base.slice(i, i + divisor);
                  newArray.push(divider);
                }
                return newArray;
              };

              batchedUpdates(() => {
                setAverageValues(dividerPerFive(response.data));
                setAverageTimeChartData(dividerPerFive(response.data)[0]);
              });
            }
            setIsLoading(false);
          }
        })
        // eslint-disable-next-line no-console
        .catch((error: AxiosResponse<any>) => console.error(error));
    }

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [
    initialDate,
    finalDate,
    projectSelctValue,
    companiesSelctValue,
    professionsSelctValue,
    areasSelctValue,
    collaboratorsSelctValue,
  ]); */
