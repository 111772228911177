import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';

import { useTrackfyApi } from '../../hooks/useTrackfyApi';
import { useAuthContext } from '../../stores/AuthContext';
import { useMovimentContext } from './context/MovimentContext';
import { OptionsContainer } from '../../components/PageStructure/styles';
import { DateRangePicker } from '../../components';
import AntdSelect from '../../components/Forms/Selects/AntdSelect';
import { getFollowingFriday } from '../../utils/DateTime';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { useAuth } from '../../hooks/useAuth';

const MovimentOptions: React.FC = () => {
  const {
    companies,
    professions,
    companiesState,
    professionsState,
    getCollaboratorsApi,
    getCompaniesApi,
    getProfessionsApi,
  } = useTrackfyApi();
  const {
    collaborators,
    collaboratorsState,
    companySelected,
    professionSelected,
    setCollaboratorSelected,
    setCollaborators,
    setCompanySelected,
    setProfessionSelected,
    setCollaboratorsState,
    updatePeriod,
  } = useMovimentContext();
  const { authData, monitorAgent } = useAuthContext();
  const compIds:string = authData? authData.grpCompanies:'t';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted){
      mixpanelTrack(monitorAgent, 'Indicadores - Movimentação', userName, authData);

      getCollaboratorsApi(
        companySelected,
        professionSelected,
        currentRequest.token,
        setCollaborators,
        setCollaboratorsState
      );
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Moviment - getAreasApi request canceled by the user.');
    };
  }, [companySelected, professionSelected]);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) getCompaniesApi(currentRequest.token);

    return () => {
      mounted = false;
      currentRequest.cancel('Moviment - getCompaniesApi request canceled by the user.');
    };
  }, []);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) getProfessionsApi(currentRequest.token);

    return () => {
      mounted = false;
      currentRequest.cancel('Moviment - getProfessionsApi request canceled by the user.');
    };
  }, []);

  return(
    <OptionsContainer>
      {((compIds!="a") && (!compIds.includes(",")))?
          <></>:
          <div>
            <AntdSelect dataRequestState={companiesState}
                        data={companies}
                        handleChange={(e: any) => {
                          setCompanySelected(parseInt(e, 10));
                          //pendoTrack('Movimentação', 'Conformidade - Filtro de empresas', 'Alteração');
                        }}
                        initialValue="Empresas"
              />
          </div>
      }
      {(compIds=="7")? //Suprimentos da Bayer (compId = 7)
        <></>:
        <div>
          <AntdSelect dataRequestState={professionsState}
                      data={professions}
                      handleChange={(e: any) => {
                        setProfessionSelected(parseInt(e, 10));
                        //pendoTrack('Indicadores', 'Movimentação - Filtro de funções', 'Alteração');
                      }}
                      initialValue="Funções"
          />
        </div>
      }
      <div>
        <AntdSelect dataRequestState={collaboratorsState}
                    data={collaborators}
                    handleChange={(e: any) => {
                      setCollaboratorSelected(parseInt(e))
                      //pendoTrack('Movimentação', 'Conformidade - Filtro de colaboradores', 'Alteração');
                    }}
                    initialValue="Colaboradores"
        />
      </div>
      <div>
        <DateRangePicker updatePeriod={updatePeriod}
                         screen="Indicadores"
                         maxDate={getFollowingFriday(new Date())}
                         component="Movimentação - "
                         selectRange
                        showToday
                        loadDate={new Date()}
        />
      </div>
      {/* <div><GeneratePdf /></div> */}
    </OptionsContainer>
  );
};

export default MovimentOptions;

/*
 return (
    <OptionsContainer>
      <OptionsRow>
        <OptionsRow>
          {((compIds!="a") && (!compIds.includes(",")))?
            <></>:
            <OptionsCol>
              <AntdSelect
                dataRequestState={companiesState}
                data={companies}
                handleChange={(e: any) => setCompanySelected(parseInt(e))}
                initialValue="Empresas"
              />
            </OptionsCol>
          }
          <OptionsCol>
            <AntdSelect
              dataRequestState={professionsState}
              data={professions}
              handleChange={(e: any) => setProfessionSelected(parseInt(e))}
              initialValue="Funções"
            />
          </OptionsCol>
          <OptionsCol>
            <AntdSelect
              dataRequestState={collaboratorsState}
              data={collaborators}
              handleChange={(e: any) => setCollaboratorSelected(parseInt(e))}
              initialValue="Colaboradores"
            />
          </OptionsCol>
          <OptionsCol>
            <DateRangePicker
              updatePeriod={updatePeriod}
              screen="Indicadores"
              maxDate={getFollowingFriday(new Date())}
              component="Movimentação - "
              selectRange
              showToday
              loadDate={new Date()}
            />
          </OptionsCol>
        </OptionsRow>
        {/* <div><GeneratePdf /></div> *///}
       /* </OptionsRow>
        </OptionsContainer>
      );*/