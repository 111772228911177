import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useIsMounted } from '../../../hooks';
import Error from '../../../Icons/Error';
import Sucess from '../../../Icons/Sucess';
import { useAuthContext } from '../../../stores';

interface IMessages {
  message: string;
  icon: JSX.Element;
}

interface TypeMessages {
  [key: string]: IMessages;
}

export const useLogin = () => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { signIn } = useAuthContext();
  const isMounted = useIsMounted();

  useEffect(() => {
    let waitLogin: ReturnType<typeof setTimeout>;
    if (message === 'sucess') {
      const auth = localStorage.getItem('authData');
      let parsedAuth = {profileData: { mainPage: '/monitoramento'}};
      if (auth) parsedAuth = JSON.parse(auth);
      const mainPage:string = parsedAuth.profileData.mainPage
      waitLogin = setTimeout(() => history.push('/' + mainPage), 1200);
    }
    return () => clearTimeout(waitLogin);
  }, [history, message]);

  const handleSignIn = async (e: any) => {
    e.preventDefault();

    if (!user || !password) {
      setMessage('warning');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      await signIn({ user, password });

      if (!isMounted.current) return history.push('/');

      setMessage('sucess');
      setLoading(false);

      history.push('/');
    } catch (error) {
      if (!isMounted.current) return;
      setMessage('error');
    }

    if (!isMounted.current) return;
    setLoading(false);
  };

  const typeMessages: TypeMessages = {
    sucess: {
      message: 'Login realizado com sucesso!',
      icon: <Sucess />,
    },
    error: {
      message: 'Ops! Senha ou usuário errados. Tente novamente!',
      icon: <Error />,
    },
    warning: {
      message: 'Informe o usuário e a senha para continuar!',
      icon: <Error />,
    },
  };

  return {
    user,
    password,
    loading,
    message,
    typeMessages,
    handleSignIn,
    setUser,
    setPassword,
  };
};
