import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import useSwr from 'swr';

import { useAuth, useIsMounted, useTrackfyApi } from '../../../../../hooks';
import cookieService from '../../../../../services/CookieService';
import { fetchData } from '../../../../../services/requests';
import { useDetectiveContext } from '../../../context/DetectiveContext';
import { MonitoringCollaboratorData } from '../../../../monitoring/types/Monitoring';
import { useCompanyFilterContext } from '../../../../../stores/CompanyFilterContext';
import { pendoTrack } from '../../../../../services/SystemMonitor';

export const useSearch = () => {
  const { CLIENT_ID } = useAuth();

  // const [active, setActive] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);
  const { generateMonitoredCollaboratorsApi } = useTrackfyApi();
  const {
    dataMonitoredCollaborators,
    updateDataMonitoredCollaborators,
    updateListCollaborators,
    searchListActive,
    setSearchListActive,
  } = useDetectiveContext();
  const [collaboratorsLoading, setCollaboratorsLoading] = useState<boolean>(false);
  const token: any = cookieService.get('token');

  const value: React.MutableRefObject<string> = useRef('');

  const currentRequest: CancelTokenSource = Axios.CancelToken.source();

  const isMounted = useIsMounted();

  const { selectedCompany } = useCompanyFilterContext();
  const companyId = selectedCompany?.id ? selectedCompany?.id : '0';

  const url = `/monitoring/collaborators${companyId ? `?companyId=${companyId}` : ''}`;

  useSwr(
    mounted ? url : null,
    async (url: string) => {
      const clientId: string | null = localStorage.getItem(CLIENT_ID)
        ? localStorage.getItem(CLIENT_ID)
        : '2';

      await fetchData(token, url, { clientId }, currentRequest.token)
        .then((res: AxiosResponse<any>) => {
          const { data } = res;

          if (!isMounted.current) return;
          updateDataMonitoredCollaborators(data);
        })
        .catch(() => {
          if (!isMounted.current) return;
          updateDataMonitoredCollaborators([]);
        });
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 30000,
    }
  );

  // Filtra os dados dos colaboradores
  const getListMonitoredCollaborators = useCallback(
    (search?: string) => {
      value.current = search || value.current;

      const collaborators = dataMonitoredCollaborators.filter(
        (collaborator: MonitoringCollaboratorData) => {
          if (!collaborator) return !collaborator;
          return (
            collaborator.collaborator
              .trim()
              .toUpperCase()
              .includes(value.current.trim().toUpperCase()) ||
            collaborator.historic[0].area
              .trim()
              .toUpperCase()
              .includes(value.current.trim().toUpperCase())
          );
        }
      );
      updateListCollaborators(collaborators);
    },
    [dataMonitoredCollaborators]
  );

  const handleClickButtonSeacrh = () => {
    value.current = '';
    getListMonitoredCollaborators();
    // setActive(!active);
    setSearchListActive(!searchListActive);
    pendoTrack('Tela de monitoramento', 'Detetive', 'Clique');
  };

  useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted = true;
    let clientId: string|null = localStorage.getItem(CLIENT_ID); 

    if(mounted){
      if(clientId != null)
         generateMonitoredCollaboratorsApi(//Number.parseInt(clientId),
                                           Number.parseInt(companyId),
                                           currentRequest.token,
                                           updateDataMonitoredCollaborators,
                                           setCollaboratorsLoading);
      else
        generateMonitoredCollaboratorsApi(//Number.parseInt('2'),
                                          Number.parseInt(companyId),
                                          currentRequest.token,
                                          updateDataMonitoredCollaborators,
                                          setCollaboratorsLoading);
    }
  
    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [companyId]);

  useEffect(() => {
    let initialized = true;

    if (initialized) {
      setMounted(true);

      if (dataMonitoredCollaborators.length > 0) {
        getListMonitoredCollaborators('');
      }
    }

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      initialized = false;
    };
  }, [dataMonitoredCollaborators]);
  
  if(collaboratorsLoading) return { searchListActive, handleClickButtonSeacrh, getListMonitoredCollaborators };

  return { searchListActive, handleClickButtonSeacrh, getListMonitoredCollaborators };
};
