import React from 'react';

const IconClock: React.FC = () => (
  <svg
    fill="none"
    height="14"
    stroke="#fff"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="3"
    viewBox="0 0 24 24"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '2px', marginTop: '2px' }}
  >
    <circle cx="12" cy="12" r="10" />
    <polyline points="12 6 12 12 15 15" />
  </svg>
);

export default IconClock;
