import React, { createContext, ReactNode, useContext, useState } from 'react';

import { MonitoringCollaboratorData } from '../../monitoring/types/Monitoring';

type DetectiveContextData = {
  historyIsActive: boolean;
  selectedPerson: number;
  personHistory: any;
  dataMonitoredCollaborators: any;
  listCollaborators: any;
  initHistory: any;
  setInitHistory: any;
  searchListActive: boolean;
  togglePopupHistory: () => void;
  updateSelectedPerson: (id: number) => void;
  updatePersonHistory: (history: MonitoringCollaboratorData[]) => void;
  updateDataMonitoredCollaborators: (collaborators: MonitoringCollaboratorData[]) => void;
  updateListCollaborators: (collaborators: MonitoringCollaboratorData[]) => void;
  setSearchListActive: (value: boolean) => void;
};

const DetectiveContext = createContext({} as DetectiveContextData);

type DetectiveProviderProps = {
  children: ReactNode;
};

export function DetectiveContextProvider({ children }: DetectiveProviderProps) {
  const [historyIsActive, setHistoryIsActive] =
    useState<boolean>(false); /* Estado do Popup do histórico dos colaboradores monitorados */
  const [selectedPerson, setSelectedPerson] = useState<number>(0);
  const [personHistory, setPersonHistory] = useState<MonitoringCollaboratorData[]>([]);
  const [listCollaborators, setListCollaborators] = useState<MonitoringCollaboratorData[]>([]);
  const [dataMonitoredCollaborators, setDataMonitoredCollaborators] = useState<
    MonitoringCollaboratorData[]
  >([]);
  const [initHistory, setInitHistory] = useState<boolean>(false);
  const [searchListActive, setSearchListActive] = useState<boolean>(false);

  function togglePopupHistory() {
    setHistoryIsActive((curr: boolean) => !curr);
  }

  function updateSelectedPerson(id: number) {
    setSelectedPerson(id);
  }

  function updatePersonHistory(history: MonitoringCollaboratorData[]) {
    setPersonHistory(history);
  }

  function updateDataMonitoredCollaborators(collaborators: MonitoringCollaboratorData[]) {
    setDataMonitoredCollaborators(collaborators);
  }

  function updateListCollaborators(collaborators: MonitoringCollaboratorData[]) {
    setListCollaborators(collaborators);
  }

  const value = {
    historyIsActive,
    selectedPerson,
    personHistory,
    dataMonitoredCollaborators,
    listCollaborators,
    searchListActive,
    togglePopupHistory,
    updateSelectedPerson,
    updatePersonHistory,
    updateDataMonitoredCollaborators,
    updateListCollaborators,
    initHistory,
    setInitHistory,
    setSearchListActive,
  };

  return <DetectiveContext.Provider value={value}>{children}</DetectiveContext.Provider>;
}

export function useDetectiveContext() {
  const context = useContext(DetectiveContext);

  if (typeof context === 'undefined') {
    throw new Error('DetectiveContext must be used within an useDetectiveContext');
  }

  return context;
}
