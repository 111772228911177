import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { Treemap, Tooltip, ResponsiveContainer } from 'recharts';

import { IconLoading } from '../../../../components/IconLoading';
import { useTrackfyApi } from '../../../../hooks/useTrackfyApi';
import { PresenceByArea } from '../../../indicators/types/Indicators';
import { useProductivityContext } from '../../context/PageProductivityContext';

import './PresenceAreaChart.css';

const PresenceAreaChart: React.FC = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { getProductivityByAreaApi } = useTrackfyApi();
  const [presenceAreaChartData, setPresenceAreaChartData] = useState<PresenceByArea[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      getProductivityByAreaApi(
        companySelected,
        professionSelected,
        initialDate,
        finalDate,
        currentRequest.token,
        setIsLoading,
        setPresenceAreaChartData
      );
    }

    return () => {
      mounted = false;
      currentRequest.cancel('PresenceAreaChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  const CustomTooltip = (e: any) => {
    if (e.active) {
      return (
        <div className="custom-tooltip">
          <h5 className="tooltipTitle">{`${e.payload[0].name}`}</h5>
        </div>
      );
    }
    return null;
  };

  if (isLoading) return <IconLoading />;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        width={500}
        height={250}
        data={[presenceAreaChartData]}
        isAnimationActive={false}
        nameKey="name"
        dataKey="value"
      >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  );
};

export default PresenceAreaChart;
