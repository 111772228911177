import { Select } from 'antd';
import styled from 'styled-components';

import './styles.css';

const { Option: OptionSelect } = Select;

export const Option = styled(OptionSelect)`
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
`;
