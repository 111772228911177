import React from 'react';

import { pendoTrack } from '../../../../../services/SystemMonitor';

type Props = {
  title: string;
  color: string;
  quantity: number;
};

export const ListHeader: React.FC<Props> = (props: Props) => {
  const { title, color, quantity } = props;

  return (
    <div onClick={pendoTrack('Tela de monitoramento', `Card de áreas: ${title}`, 'Clique')}>
      <div
        onClick={pendoTrack('Tela de monitoramento', `Card de áreas: ${title}`, 'Clique')}
        className="area-quantity"
        style={{ backgroundColor: color }}
        aria-hidden="true"
      >
        {quantity}
      </div>
      <span
        onClick={pendoTrack('Tela de monitoramento', `Card de áreas: ${title}`, 'Clique')}
        className="area-title"
      >
        {title}
      </span>
    </div>
  );
};
