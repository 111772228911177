import { AxiosResponse, CancelToken } from 'axios';

import api from './api';

const getData = async (token: string, url: string, params?: {}, cancelToken?: CancelToken) => {
  const response: AxiosResponse<any> = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
    cancelToken,
  });
  return response;
};

const postData = async (token: string, url: string, json: any) => {
  const response: any = await api.post(url, json, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export async function fetchData(
  token: string,
  url: string,
  params?: {},
  cancelToken?: CancelToken
) {
  const data = Promise.resolve(getData(token, url, params, cancelToken));
  return data;
}

export async function sendData(token: string, url: string, json: any) {
  const data = await Promise.resolve(postData(token, url, json));

  return data;
}
