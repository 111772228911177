import React, { useEffect } from 'react';
import Axios, { CancelTokenSource } from 'axios';
import FullCalendar from '@fullcalendar/react';
import ptBr from '@fullcalendar/core/locales/pt-br';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import { IconLoading } from '../../../components/IconLoading';
import { useTrackfyApi } from '../../../hooks/useTrackfyApi';
import { useMovimentContext } from '../context/MovimentContext';
import { useCalendar } from './hooks/useCalendar';

import './FullCallendar.css';

const FullCallendar: React.FC = () => {
  const { initialDate, finalDate, collaboratorSelected } = useMovimentContext();
  const { getCalendarDataApi } = useTrackfyApi();
  const {
    events,
    setEvents,
    isLoading,
    setIsLoading,
    showEventDetails,
    logData,
    initDate,
    setInitDate,
  } = useCalendar();
  const optionsView = ['timeGridWeek', 'timeGridDay', 'dayGridMonth'];

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getCalendarDataApi(
        collaboratorSelected,
        initialDate,
        finalDate,
        currentRequest.token,
        setEvents,
        setInitDate,
        setIsLoading
      );

    return () => {
      mounted = false;
      currentRequest.cancel('FullCallendar request canceled by the user.');
    };
  }, [collaboratorSelected, initialDate, finalDate]);

  if (isLoading) return <IconLoading />;
  return (
    <FullCalendar
      viewClassNames="callendar"
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      locale={ptBr}
      headerToolbar={{
        left: '',
        center: '',
        // right: '',
      }}
      initialView={optionsView[0]}
      initialDate={initDate}
      selectable
      weekends
      dayMaxEvents
      slotLabelFormat={[{ hour: '2-digit', minute: '2-digit', hour12: false }]}
      eventBorderColor="transparent"
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        hour12: false,
        omitZeroMinute: false,
      }}
      initialEvents={events}
      slotMinTime="07:00:00"
      slotMaxTime="24:00:00"
      slotDuration="00:02:00"
      eventClick={logData}
      eventMouseEnter={showEventDetails}
      eventOverlap={false}
      slotEventOverlap={false}
    />
  );
};

export default FullCallendar;

/* useEffect(() => {
      const currentRequest: CancelTokenSource = Axios.CancelToken.source();
      let mounted: boolean = true;

      getCalendarData(currentRequest.token, projectSelctValue, collaboratorsSelctValue)
      .then((response: AxiosResponse<any>) => {
        if (mounted) {
          if (!response.data || response.data.length===0)
            setEvents([]);
          else{
            setEvents(response.data);
            setInitDate(new Date(response.data[0].start));
          }
          setIsLoading(false);
        }
      })
      .catch((error: AxiosResponse<any>) => console.log(error));

      return () => {
        currentRequest.cancel('Operation canceled by the user.');
        mounted = false;
      };
  }, [initialDate, finalDate, projectSelctValue, collaboratorsSelctValue]); */

/* const getCalendarData= async (
      cancelToken: CancelToken,
      projValue?: number,
      collaboratorValue?:number,
    ) => {
      const userId = localStorage.getItem(USER_ID) ? localStorage.getItem(USER_ID) : '1';
      const initDate: number = moment(initialDate).startOf('day').valueOf();
      const fimDate: number = moment(finalDate).valueOf();

      let projectoValue: number | undefined = projValue;
      let colValue: number | undefined = collaboratorValue;

      if (projectSelctValue && projectSelctValue !== 0) projectoValue = projectSelctValue;
      if (collaboratorsSelctValue && collaboratorsSelctValue !== 0) colValue = collaboratorsSelctValue;

      setIsLoading(true);

      return fetchData(
        token,
        `${baseUrl}/statistics/calendar`,
        {
          project: projectoValue,
          collaboratorId: colValue,
          userId:userId,
          initialDate: initDate,
          finalDate: fimDate
        },
        cancelToken
      );
  }; */

/* const handleclick = (e:any) => {
      console.log(e);
  };

  const handleMouseEnter = (e:any) =>{
    if(e.event.endStr.length > 0){
      const title: string = e.event._def.extendedProps.tooltip;
      const enterAreaTimestamp:string = moment(e.event.start).format('DD/MM/YY HH:mm');
      const exitAreaTimestamp:string = moment(e.event.end).format('DD/MM/YY HH:mm');
      const contentHtml: string = "<div class='tooltipTitleContainer'>"
                                    + "<div style=\"background:" + e.event.backgroundColor + ";\" class='pointlabel'></div>"
                                    + "<h5 class='tooltipTitle'><strong>" + title + "</strong></h5>"
                                + "</div>"
                                + "<h5 class='tooltipEnter'>entrada: " + enterAreaTimestamp + "</h5>"
                                + "<h5 class='tooltipExit'>saida: " + exitAreaTimestamp + "</h5>";
      tippy(e.el, {
          allowHTML: true,
          content: contentHtml,
          theme: 'custom',
      });
    }
  }; */
