import React from 'react';

import { Card, Area } from './components';

export const MonitoredAreasContainer: React.FC = () => (
  <>
    <Card titulo="Áreas Monitoradas" />
    <Area />
  </>
);
