import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';

import { useTrackfyApi } from '../../hooks/useTrackfyApi';
import { useProductivityContext } from './context/PageProductivityContext';
import { OptionsContainer } from '../../components/PageStructure/styles';
import { useAuthContext } from '../../stores/AuthContext';
import { DateRangePicker } from '../../components';
import AntdSelect from '../../components/Forms/Selects/AntdSelect';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { getFollowingFriday } from '../../utils/DateTime';
import { useAuth } from '../../hooks/useAuth';


const ProductivityOptions: React.FC = () => {
  const {
    companies,
    professions,
    companiesState,
    professionsState,
    getCompaniesApi,
    getProfessionsApi,
  } = useTrackfyApi();
  const { setCompanySelected, setProfessionSelected, updatePeriod } = useProductivityContext();
  const {authData, monitorAgent} = useAuthContext();
  const compIds:string = authData? authData.grpCompanies:'t';
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      // updatePeriod(moment(),moment());
      mixpanelTrack(monitorAgent, 'Indicadores - Produtividade', userName, authData);
      getCompaniesApi(currentRequest.token);
    }

    return () => {
      mounted = false;
      currentRequest.cancel('Productivity - getCompaniesApi request canceled by the user.');
    };
  }, []);

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) getProfessionsApi(currentRequest.token);

    return () => {
      mounted = false;
      currentRequest.cancel('Productivity - getProfessionsApi request canceled by the user.');
    };
  }, []);

  return(
    <OptionsContainer>
      {((compIds!="a") && (!compIds.includes(",")))?
          <></>:
          <div>
            <AntdSelect dataRequestState={companiesState}
                        data={companies}
                        handleChange={(e: any) => {
                          setCompanySelected(parseInt(e, 10));
                          //pendoTrack('Indicadores', 'Produtividade - Filtro de empresas', 'Alteração');
                        }}
                        initialValue="Empresas"
              />
          </div>
      }
      {(compIds=="7")? //Suprimentos da Bayer (compId = 7)
        <></>:
        <div>
          <AntdSelect dataRequestState={professionsState}
                      data={professions}
                      handleChange={(e: any) => {
                        setProfessionSelected(parseInt(e, 10));
                        //pendoTrack('Indicadores', 'Conformidade - Filtro de funções', 'Alteração');
                      }}
                      initialValue="Funções"
          />
        </div>
      }     
      <div>
        <DateRangePicker updatePeriod={updatePeriod}
                         screen="Indicadores"
                         maxDate={getFollowingFriday(new Date())}
                         component="Produtividade -"
                         selectRange
                        showToday
                        loadDate={new Date()}
        />
      </div>
      {/* <div><GeneratePdf /></div> */}
    </OptionsContainer>
  );
};

export default ProductivityOptions;


/*return (
  <OptionsContainer>
    <OptionsRow>
      <OptionsRow>
        {((compIds!="a") && (!compIds.includes(",")))?
          <></>:
          <OptionsCol>
            <AntdSelect
              dataRequestState={companiesState}
              data={companies}
              handleChange={(e: any) => setCompanySelected(parseInt(e))}
              initialValue="Empresas"
            />
          </OptionsCol>
        }
        <OptionsCol>
          <AntdSelect
            dataRequestState={professionsState}
            data={professions}
            handleChange={(e: any) => setProfessionSelected(parseInt(e))}
            initialValue="Funções"
          />
        </OptionsCol>
        <OptionsCol>
          <DateRangePicker
            updatePeriod={updatePeriod}
            screen="Indicadores"
            maxDate={getFollowingFriday(new Date())}
            component="Produtividade -"
            selectRange
            showToday
            loadDate={new Date()}
          />
        </OptionsCol>
      </OptionsRow>
      {/* <div><GeneratePdf /></div> *///}
    /*</OptionsRow>
  </OptionsContainer>
);*/