import { SignIn, IAuthRequest, IAuthResponse, GetRefreshToken } from '.';
import api from '../../api';

export const signIn: SignIn = async (request: IAuthRequest): Promise<IAuthResponse> => {
  const { user, password } = request;
  const body = { user, password };

  const response = await api.post<IAuthResponse>('auth/login', body);
  return response.data;
};

export const getRefreshToken: GetRefreshToken = async (refreshToken: string): Promise<string> => {
  const response = await api.post('auth/refreshToken', {
    refreshToken,
  });
  const { accessToken } = response.data;
  return accessToken;
};
