import { Collapse } from 'antd';
import React, { useRef } from 'react';
import ScrollBar from 'react-custom-scrollbars';

import { PeopleInArea, PeopleInAreaDate } from '../../../../indicators/types/Indicators';
import BarChart from '../BarChart/BarChart';
import ListHeader from './ListHeader';

import './List.css';

const { Panel } = Collapse;

type Props = {
  monitoredData: PeopleInAreaDate[];
};

const List: React.FC<Props> = ({ monitoredData }) => {
  const ScrollbarsRef: React.MutableRefObject<any> = useRef<any>(null);

  const ContainerAreaMonitored = () => (
    <ScrollBar ref={ScrollbarsRef} thumbMinSize={1} thumbSize={1} autoHide>
      <Collapse
        className="list-collapse"
        defaultActiveKey={['0']}
        expandIconPosition="right"
        accordion
        onChange={() => {
          setTimeout(() => ScrollbarsRef.current && ScrollbarsRef.current.forceUpdate(), 250);
        }}
      >
        {monitoredData.map((category: PeopleInAreaDate) => (
          <Panel
            className="panel-collapse"
            header={<ListHeader title={category.date} />}
            key={Math.random().toString()}
          >
            {category.data &&
              category.data.map((data: PeopleInArea) => (
                <div key={data.name + Math.random().toString()} className="panel-container">
                  <BarChart
                    label={data.name}
                    value={data.value}
                    valueTarget={data.valueTarget}
                    color={data.color}
                  />
                </div>
              ))}
          </Panel>
        ))}
      </Collapse>
    </ScrollBar>
  );

  return <ContainerAreaMonitored />;
};

export default List;
