import { Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { pendoTrack } from '../../../../../services/SystemMonitor';
import { useIsMounted } from '../../../../../hooks';
import Search from '../../../../../Icons/Search';

type Props = {
  active: boolean;
  onClick: () => any;
  onSearch: (value: string) => any;
};

export const SearchInput: React.FC<Props> = (props: Props) => {
  const { active, onClick, onSearch } = props;
  const [search, setSearch] = useState<string>('');
  const isMounted = useIsMounted();

  const handleClickOpenSearch = async () => {
    onClick();

    if (active) {
      setTimeout(() => {
        if (!isMounted.current) return;
        setSearch('');
      }, 1000);
    }
  };

  const handleChangeInputSearch = async (e: any) => {
    const { value } = e.target;
    
    if (value.toString().toUpperCase() === 'BRIGADISTA'){
      pendoTrack("Monitoring", "Detective", "Brigadista");
    }

    if (value.toString().toUpperCase() === 'EMPILHADEIRA'){
      pendoTrack("Monitoring", "Detective", "Maquinas");
      pendoTrack("Monitoring", "Detective", "Empilhadeiras");
    }

    if (value.toString().toUpperCase() === 'TRANSPALETEIRA'){
      pendoTrack("Monitoring", "Detective", "Maquinas");
      pendoTrack("Monitoring", "Detective", "Transpaleteira");
    }

    if (value.toString().toUpperCase() === 'TRANSPALETEIRA PATOLADA'){
      pendoTrack("Monitoring", "Detective", "Maquinas");
      pendoTrack("Monitoring", "Detective", "Transpaleteira Patolada");
    }

    setSearch(value);
  };

  const refreshListMonitoredCollaborators = async () => {
    await onSearch(search);
  };

  useEffect(() => {
    refreshListMonitoredCollaborators();
  }, [search]);

  return (
    <div>
      <Input
        type="search"
        value={search}
        className="search-input"
        placeholder="Pesquisar colaborador"
        onChange={handleChangeInputSearch}
        prefix={
          <div className="search-icon-container" onClick={handleClickOpenSearch}>
            <Search />
          </div>
        }
      />
    </div>
  );
};
