import React from 'react';

import { Notification } from '../../components/Notification';

import { MapContainer } from '..';

import './styles.css';

export const MonitoringContainer: React.FC<{}> = () => (
  <>
    {/* Desabilita enquanto funcionalidade não está disponível */}
    {false && <Notification />}
    <MapContainer />
  </>
);
