import { RouteKey } from '../enum';
import { DailySummary } from '../pages/DailySummary';
import { Indicators } from '../pages/Indicators';
import { Login } from '../pages/Login';
import { Monitoring } from '../pages/Monitoring';
import { Reports } from '../pages/Reports';
import { MachinesSummary } from '../pages/MachinesSummary';

export const routesConfig = {
  [RouteKey.login]: Login,
  [RouteKey.monitoramento]: Monitoring,
  [RouteKey.indicadores]: Indicators,
  [RouteKey.relatorios]: Reports,
  [RouteKey.sumario]: DailySummary,
  [RouteKey.sumariodemaquinas]: MachinesSummary,
};
