import { getConfig } from '../../../config/app.config';
import { ConfigKey } from '../../../enum/ConfigKey';
import * as integration from './integration';
import * as mock from './mock';

const selectedModule = getConfig(ConfigKey.REACT_APP_USE_MOCKS) === 'true' ? mock : integration;

export type IRoutes = {
	key: string;
	path: string;
}

export type IMenu = {
	key: string;
	description: string;
	icon: string;
	path: string;
}

export type IAuthProfilePayload = {
  userId: number;
  clientId: number;
  companyId:number;
  grpCompanies:string;
  name: string;
  email: string;
  persona: string;
  mainPage: string;
	routes: IRoutes[];
	menu: IMenu[];
};

export type IAuthProfileAuthorization = {
  superUser: boolean;
  admin: boolean;
  rules: string[];
};

export type IAuthResponse = {
  access_token: string;
  refresh_token: string;
  profileData: IAuthProfilePayload;
  authorization: IAuthProfileAuthorization;
};

export type IAuthRequest = {
  user: string;
  password: string;
};

export type SignIn = (request: IAuthRequest) => Promise<IAuthResponse>;
export type GetRefreshToken = (refreshToken: string) => Promise<string>;

export const signIn: SignIn = selectedModule.signIn;
export const getRefreshToken: GetRefreshToken = selectedModule.getRefreshToken;
