import React from 'react';

import { PageHeader } from '../../components/PageStructure/PageHeader';
import { Container } from '../../components/PageStructure/styles';
import { PageTabs } from './components/PageTabs';
//import './styles.css';

export const IndicatorsContainer: React.FC<{}> = () => (
  <Container>
    <PageHeader title="Indicadores" />
    <PageTabs />
  </Container>
);
