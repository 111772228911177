import React, { createContext, ReactNode, useState, useContext } from 'react';
import { useAuthContext } from '../../../stores/AuthContext';

import { Collaborator } from '../../../types/Filters';

type ReportsContextProps = {
  collaborators: Collaborator[];
  collaboratorsState: string;
  initialDate: any;
  finalDate: any;
  companySelected: number;
  professionSelected: number;
  collaboratorSelected: number;
  loadingAccessControl: boolean;
  loadingAreaMonitoringHistory: boolean;
  loadingDailyReport: boolean;
  setCollaborators: (collaborators: Collaborator[]) => void;
  setCollaboratorsState: (value: string) => void;
  setCompanySelected: (companie: number) => void;
  setProfessionSelected: (profession: number) => void;
  setCollaboratorSelected: (collaborator: number) => void;
  setLoadingAccessControl: (value: boolean) => void;
  setLoadingAreaMonitoringHistory: (value: boolean) => void;
  setLoadingDailyReport: (value: boolean) => void;
  updatePeriod: (initial: any, final: any) => void;
};

type Props = {
  children: ReactNode;
};

const ReportsContext = createContext<ReportsContextProps>({} as ReportsContextProps);

export function ReportsContextProvider(props: Props) {
  const { children } = props;

  const {authData} = useAuthContext();
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [collaboratorsState, setCollaboratorsState] = useState<string>('');
  const [initialDate, setInitialDate] = useState<any>();
  const [finalDate, setFinalDate] = useState<any>();
  const [professionSelected, setProfessionSelected] = useState<number>(0);
  const [collaboratorSelected, setCollaboratorSelected] = useState<number>(0);
  const [loadingAccessControl, setLoadingAccessControl] = useState<boolean>(false);
  const [loadingAreaMonitoringHistory, setLoadingAreaMonitoringHistory] = useState<boolean>(false);
  const [loadingDailyReport, setLoadingDailyReport] = useState<boolean>(false);

  const companyId = (authData && authData.grpCompanies != 'a' && (!authData.grpCompanies.includes(","))) ?
    authData.companyId:0;
    
  const [companySelected, setCompanySelected] = useState<number>(companyId);

  const updatePeriod = (initial: any, final: any) => {
    setInitialDate(initial);
    setFinalDate(final);
  };

  const value = {
    collaborators,
    setCollaborators,
    collaboratorsState,
    setCollaboratorsState,
    initialDate,
    finalDate,
    companySelected,
    setCompanySelected,
    professionSelected,
    setProfessionSelected,
    collaboratorSelected,
    setCollaboratorSelected,
    loadingAccessControl,
    setLoadingAccessControl,
    loadingAreaMonitoringHistory,
    setLoadingAreaMonitoringHistory,
    loadingDailyReport,
    setLoadingDailyReport,
    updatePeriod,
  };

  return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
}

export function useReportsContext() {
  const context = useContext(ReportsContext);

  if (typeof context === 'undefined') {
    throw new Error('ReportsContext must be used within an useReportsContext');
  }

  return context;
}
