import React from 'react';

const IconArrowUp: React.FC = () => (
  <svg 
    fill="none"
    width="24"
    height="24"
    style={{ marginBottom: '-4px' }}
    xmlns="http://www.w3.org/2000/svg"
  >
   <path
      d="M12 19V5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12L12 5L19 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconArrowUp;