import { useCallback, useEffect, useState } from 'react';

import { ResultListItem } from '../../../components/Search/components';
import { getCompanies } from '../../../services/api/TrackfyService';
import { useCompanyFilterContext } from '../../../stores/CompanyFilterContext';
import { Company } from '../../../types/Filters';

export const useCompanyFilter = () => {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState('');
  const [resultList, setResultList] = useState<ResultListItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<ResultListItem | null>();

  const { handleSetCompanyFilter } = useCompanyFilterContext();

  useEffect(() => {
    getCompanies(true).then((companies: Company[]) => {
      const resultListItem: ResultListItem[] = companies.map(
        (company: Company): ResultListItem => ({
          id: company.id.toString(),
          label: company.name,
        })
      );

      setResultList(resultListItem);
    });
  }, []);

  const handleActiveFilter = useCallback(
    (active: boolean) => {
      setIsActive(active);
    },
    [isActive]
  );

  const handleSetSelectedItem = useCallback(
    (item: ResultListItem | null) => {
      setSelectedItem(item);
      handleSetCompanyFilter(item);
    },
    [selectedItem]
  );

  return {
    value,
    isActive,
    resultList,
    selectedItem,
    handleSetSelectedItem,
    setValue,
    handleActiveFilter,
  };
};
