import { Switch } from 'antd';
import React, { useEffect } from 'react';
import { ZoomControl } from 'react-mapbox-gl';

import { DetectiveContainer, MonitoredAreasContainer } from '..';

import 'mapbox-gl/dist/mapbox-gl.css';
import './styles.css';
import * as S from './styles';
import { useMap } from './hooks/useMap';
import { DetectiveContextProvider } from '../detective/context/DetectiveContext';
import { AreasContextProvider } from './context/AreasContext';
import { IconLoading } from '../../components/IconLoading';
import { CompanyFilter } from './components';
import { CompanyFilterContextProvider } from '../../stores/CompanyFilterContext';
import { useAuthContext } from '../../stores/AuthContext';
import { useAuth } from '../../hooks/useAuth';
import { mixpanelTrack } from '../../services/SystemMonitor';

export const MapContainer: React.FC<{}> = () => {
  const { lat, long, Map, satelite, handleChange, initializeMap, mapStyle } = useMap();
  const {authData, monitorAgent} = useAuthContext();
  const { USER_NAME } = useAuth();
  const userName = localStorage.getItem(USER_NAME);
  let companies = "";
  
  if(authData)
    companies = authData.grpCompanies;

    useEffect(()=>{
      mixpanelTrack(monitorAgent, 'Monitoramento', userName, authData);
    },[])

  const handleDblClick = (_map:any, event:any) => {
    if (process.env.NODE_ENV != 'production')
      console.log(event.lngLat);
  };

  return (
    <>
      {initializeMap ? (
        <div>
          <Map
            style={mapStyle}
            containerStyle={{ height: '100vh' }}
            center={[long, lat]}
            zoom={[17]}
            onDblClick={handleDblClick}
          >
            <CompanyFilterContextProvider>
              <AreasContextProvider>
                <S.MonitoredAreas>
                  <MonitoredAreasContainer />
                </S.MonitoredAreas>
              </AreasContextProvider>

              {((companies!="a") && (!companies.includes(",")))?
                <></>:
                <S.FilterContainer>
                  <CompanyFilter />
                </S.FilterContainer>
              }
              

              <DetectiveContextProvider>
                <S.Detective>
                  <DetectiveContainer />
                </S.Detective>
              </DetectiveContextProvider>
            </CompanyFilterContextProvider>

            {/*<ZoomControl position="bottom-right" />*/}
          </Map>
          <S.SwitchContainer>
            <S.StreetLabel checked={satelite}>Street</S.StreetLabel>
            <Switch style={{ marginTop: '3px' }} onChange={handleChange} />
            <S.SateliteLabel checked={satelite}>Satélite</S.SateliteLabel>
          </S.SwitchContainer>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLoading />
        </div>
      )}
    </>
  );
};
