import React, { ReactElement } from 'react';

import { Tab } from '../../../components/Tabs/Tab';
import { Tabs } from '../../../components/Tabs';
import PageConformity from '../../conformity/PageConformity';
import PageProductivity from '../../productivity/PageProductivity';
import Moviment from '../../moviment/Moviment';
import { useAuthContext } from '../../../stores/AuthContext';

export const PageTabs: React.FC = () => {
  const {authData} = useAuthContext();
  const companies: string = authData ? authData.grpCompanies : "";
  const children: ReactElement[] = [];

  if(companies!="7"){
      children.push(
        <Tab title="Conformidade">
          <PageConformity />
        </Tab>);
  }

  children.push(
      <Tab title="Produtividade">
        <PageProductivity />
      </Tab>);

  children.push(
      <Tab title="Movimentação">
        <Moviment />
      </Tab>);

  return <Tabs children={children} />;
}
