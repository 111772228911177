import React from 'react';
import Axios, { CancelTokenSource } from 'axios';

import { Tbody, Trstyled, EmptyContainer } from '../../../components/Table/styled';
import { Modal } from 'antd';
import AntdSelect from '../../../components/Forms/Selects/AntdSelect';

import { Button, TrStyled2 } from '../styles';
import { IconLoading } from '../../../components/IconLoading';
import Incident from '../../../Icons/Incident';
import { useTrackfyApi } from '../../../hooks';

type TableBodyProps = {
  tableData: Array<string[]>;
  loading: boolean;
  isEmpty: boolean;
  idValues: string[];
  currentStatus: string[];
  setCurrentStatus: (value: string[]) => void;
};

const MachinesTableBody: React.FC<TableBodyProps> = (props: TableBodyProps) => {
  const { tableData, loading, isEmpty, idValues, currentStatus, setCurrentStatus } = props;
  const { updateMachinesStatusApi } = useTrackfyApi();
  const currentRequest: CancelTokenSource = Axios.CancelToken.source();

  const selectOptions = [
    { id: 1, name: 'disponível' },
    { id: 2, name: 'calibração' },
    { id: 3, name: 'preventiva' },
    { id: 4, name: 'em emprestimo' },
  ];

  const showModalComponent = (index: number) => {
    Modal.info({
      title: 'Selecione o status atual da máquina',
      content: (
        <div>
          <AntdSelect
            dataRequestState={'done'}
            data={selectOptions}
            handleChange={(e: any) => {
              const option = selectOptions.find((item) => {
                return item.id == e;
              });

              if (option) {
                const newValues = currentStatus.map((value, i) =>
                  i === index ? option.name : value
                );
                setCurrentStatus(newValues);
                updateMachinesStatusApi(currentRequest.token, idValues[index], option.name);
              }
              
              //pendoTrack('Sumário de Máquinas', 'Filtro de Status', 'Alteração');
            }}
            initialValue={'Status das Máquinas'}
          />
        </div>
      ),
      onOk() {},
    });
  };

  if (loading)
    return (
      <Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <IconLoading />
          </td>
        </tr>
      </Tbody>
    );

  if (isEmpty)
    return (
      <Tbody>
        <tr>
          <td width="100%" height="100%" colSpan={100}>
            <EmptyContainer>
              <Incident />
              <span>Não há dados para o período selecinado</span>
            </EmptyContainer>
          </td>
        </tr>
      </Tbody>
    );

  return (
    <Tbody>
      {tableData.map((item: string[], index: number) =>
        index % 2 === 0 ? (
          <Trstyled key={`${index}-${item}`} height={'35px'}>
            {item.map((val) =>
              val.substring(0, 1) == '$' ? (
                <td key={`${index}-${val}`}>
                  <Button key={`${index}-${val}-button`} onClick={() => showModalComponent(index)}>
                    {currentStatus[index]}
                  </Button>
                </td>
              ) : (
                <td min-width="20px" key={`${index}-${val}`}>
                  {val}
                </td>
              )
            )}
          </Trstyled>
        ) : (
          <TrStyled2 key={`${index}-${item}`}>
            {item.map((val) =>
              val.substring(0, 1) == '$' ? (
                <td key={`${index}-${val}`}>
                  <Button key={`${index}-${val}-button`} onClick={() => showModalComponent(index)}>
                    {currentStatus[index]}
                  </Button>
                </td>
              ) : (
                <td min-width="20" key={`${index}-${val}`}>
                  {val}
                </td>
              )
            )}
          </TrStyled2>
        )
      )}
    </Tbody>
  );
};

export default MachinesTableBody;

