import { useState } from 'react';

export const useTimeline = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const formatTimelineDate = (timelineData: any[]): string => {
    if (timelineData && timelineData.length > 0) {
      if (timelineData[0].date) {
        return `${timelineData[0].date.substring(8, 10)}/${timelineData[0].date.substring(
          5,
          7
        )}/${timelineData[0].date.substring(0, 4)}`;
      }
    }
    return '';
  };

  const dividerPerEighteen = (base: any[]): Array<any[]> => {
    const divisor: number = 18;
    const newArray: Array<any[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: any[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  const dividerPerTwentyfour = (base: any[]): Array<any[]> => {
    const divisor: number = 24;
    const newArray: Array<any[]> = [];

    for (let i = 0; i < base.length; i += divisor) {
      const divider: any[] = base.slice(i, i + divisor);
      newArray.push(divider);
    }
    return newArray;
  };

  return {
    isLoading,
    isEmpty,
    setIsLoading,
    setIsEmpty,
    formatTimelineDate,
    dividerPerEighteen,
    dividerPerTwentyfour,
  };
};
