import React from 'react';
import styled from 'styled-components';

import { Shadow } from '../../components';
import { ContentContainer } from '../../components/PageStructure/styles';
import { AverageTimeContextProvider } from './charts/AverageTimeChart/context/AverageTimeContext';
import AverageTimeChart from './charts/AverageTimeChart/AverageTimeChart';
import AverageTimeChartHeader from './charts/AverageTimeChart/AverageTimeChartHeader';
import { PeopleInAreaContextProvider } from './charts/PeopleInAreasChart/context/PeopleInAreasContext';
import PeopleInAreasChart from './charts/PeopleInAreasChart/PeopleInAreasChart';
import PeopleInAreasChartHeader from './charts/PeopleInAreasChart/PeopleInAreasChartHeader';
import PresenceAreaChart from './charts/PresenceAreaChart/PresenceAreaChart';
import PresenceAreaChartHeader from './charts/PresenceAreaChart/PresenceAreaChartHeader';
import { RankContextProvider } from './charts/RankChart/context/RankContext';
import RankChart from './charts/RankChart/RankChart';
import RankChartHeader from './charts/RankChart/RankChartHeader';
import { ProductivityContextProvider } from './context/PageProductivityContext';
import ProductivityOptions from './ProductivityOptions';

const ChartRow = styled.div`
  min-height: 40vh;
  height: 100%;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  justify-content: space-between;
  gap: 3.5rem;

  @media (max-width: 1200px) {
    &&& {
      grid-template-columns: 1fr;
    }
  }
`;

const Card = styled(Shadow)`
  width: 100%;
  height: 100%;
  min-height: 590px;
  padding: 2.4rem;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const CardAvgcontent = styled.div`
  height: 100%;
  padding: 0 1rem;
  max-height: 385px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  & svg {
    border-radius: 10px;

    & text {
      font: normal 500 1.3rem/1 Work Sans, sans-serif !important;
      fill: #4f5882 !important;
    }
  }
`;

const CardRankcontent = styled.div`
  margin-top: 29px;
  max-height: 40vh;
  height: calc(100% - 236px);
  display: flex;
  justify-content: center;
  align-items: start;
  overflow: auto;
`;

const CardChart = styled.div`
  max-height: 100%;
  height: 100%;
  padding-bottom: 4.5rem;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    max-height: 515px;
  }

  & svg {
    border-radius: 10px;

    & text {
      font-family: Work Sans, sans-serif !important;
      font-weight: 600;
      fill: #fff !important;
    }
  }
`;

const PageProductivity: React.FC = () => (
  <ContentContainer value="">
    <ProductivityContextProvider>
      <ProductivityOptions />
      <ChartRow>
        <Card>
          <AverageTimeContextProvider>
            <AverageTimeChartHeader />
            <CardAvgcontent>
              <AverageTimeChart />
            </CardAvgcontent>
          </AverageTimeContextProvider>
        </Card>
        <Card>
          <RankContextProvider>
            <RankChartHeader />
            <CardRankcontent>
              <RankChart />
            </CardRankcontent>
          </RankContextProvider>
        </Card>
      </ChartRow>
      <ChartRow>
        <Card>
          <PeopleInAreaContextProvider>
            <PeopleInAreasChartHeader />
            <PeopleInAreasChart />
          </PeopleInAreaContextProvider>
        </Card>
        <Card>
          <PresenceAreaChartHeader />
          <CardChart>
            <PresenceAreaChart />
          </CardChart>
        </Card>
      </ChartRow>
    </ProductivityContextProvider>
  </ContentContainer>
);

export default PageProductivity;
