import React from 'react';
import styled from 'styled-components';

import { TCollaboratorTimelineFooter } from '../../indicators/types/Indicators';

const Point = styled.span`
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 96px;
  margin-right: 1rem;
  background: ${({ background }: TCollaboratorTimelineFooter) => background};
`;

const Label = styled.span`
  margin-right: 2rem;
  font: normal 500 1.4rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
`;

const CollaboratorTimelineFooter: React.FC<TCollaboratorTimelineFooter> = ({
  name,
  background,
}: TCollaboratorTimelineFooter) => (
  <>
    <Point background={background} />
    <Label>{name}</Label>
  </>
);

export default CollaboratorTimelineFooter;
