import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  background: rgb(255, 255, 255);
  position: fixed;
  left: 80px;
  bottom: 40px;
  width: 210px;
  flex-direction: row;
  padding-top: 5px;
  border-radius: 20px;
  justify-content: space-around;
`;

export interface LabelProps {
  checked?: boolean;
}

export const StreetLabel = styled.h3<LabelProps>`
  color: rgb(79, 88, 130);
  font-weight: ${(props) => (props.checked ? 'normal' : 'bold')};
  margin-left: 5px;
`;

export const SateliteLabel = styled.h3<LabelProps>`
  color: rgb(79, 88, 130);
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};
  margin-right: 5px;
`;

export const MonitoredAreas = styled.div`
  position: absolute;
  width: 20%;
  top: 18px;
  left: 40px;
`;

export const FilterContainer = styled.div`
  position: absolute;
  width: calc(40% - 60px);
  top: 18px;
  left: calc(20% + 60px);
`;

export const Detective = styled.div`
  position: absolute;
  width: calc(40% - 60px);
  top: 18px;
  left: calc(60% + 20px);

  display: flex;
  justify-content: end;
`;
