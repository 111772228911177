import { AxiosResponse, CancelToken } from 'axios';
import api from '../../api';

import { GetCompanies, GetMachinesSummary, GetTimelineData, TimelineData } from '.';
import { Timeline } from '../../../containers/indicators/types/Indicators';
import { MachinesSummaryData } from '../../../containers/machinesSummary/types/MachinesSummaryTypes';
import { Company } from '../../../types/Filters';
import { IHeatmapArea } from '../../../containers/moviment/HeatMap/types/HeatMapData';



export const getTimelineData: GetTimelineData = async (data: TimelineData): Promise<Timeline[]> => {
  const { companyId, professionId, areaId, initialDate, finalDate, currentDate, cancelToken } =
    data;

  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/timeline', {
      params: {
        company: companyId,
        profession: professionId,
        area: areaId,
        initialDate,
        finalDate,
        currentDate,
      },
      cancelToken,
    });

    return result.data as Timeline[];
  } catch (error) {
    return [];
  }
};

export const getCompanies: GetCompanies = async (operational:boolean, cancelToken?: CancelToken): Promise<Company[]> => {
  try {
    const result: AxiosResponse<any> = await api.get(`v1/companies`, { 
      params: {
        operational: operational
      },
      cancelToken 
    });
    return result.data as Company[];
  } catch (error) {
    return [] as Company[];
  }
};

export const getMachinesSummary: GetMachinesSummary = async (cancelToken?: CancelToken): Promise<MachinesSummaryData> => {
  try {
    const result: AxiosResponse<any> = await api.get(`reports/v1/machinessummary`, {
      params: {},
      cancelToken,
    });

    return result.data as MachinesSummaryData;
  } catch (error) {
    return { data: [], statusValues: [], idValues:[] } as MachinesSummaryData;
  }
};

export const putMachineStatus: any = async (status: string, id: string, cancelToken?: CancelToken): Promise<any> => {
  try {
    const result: AxiosResponse<any> = await api.put(`reports/v1/machinestatus`,
      {
        status: status,
        machineCod: id,
      },
      {
        cancelToken,
      }
    );
    return result.data;
  } catch (error) {
    return {};
  }
};

export const getHeatMapData = async (
  companyId: number,
  professionId: number,
  initialDate: number,
  finalDate: number,
  cancelToken: CancelToken
): Promise<IHeatmapArea[]> => {
  try {
    const result: AxiosResponse<any> = await api.get('statistics/v1/heatmaps', {
      params: {
        company: companyId,
        profession: professionId,
        initialDate: initialDate,
        finalDate: finalDate,
      },
      cancelToken,
    });

    return result.data as IHeatmapArea[];
  } catch (error) {
    return [] as IHeatmapArea[];
  }
};