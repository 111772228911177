import styled, { keyframes } from 'styled-components';

import BackgroundLogin from '../../assets/background_login.jpg';

export const Background = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url(${BackgroundLogin}) 30% 55% / cover no-repeat #2f4cdd;
  background-blend-mode: soft-light;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 112px;
    background: linear-gradient(270deg, #273dab -30.8%, rgba(32, 55, 169, 0) 37.05%);
    opacity: 0.45;
    display: block;
  }
`;

export const TextTitle = styled.div`
  font: normal 3rem/1.5 Work Sans, sans-serif;
  letter-spacing: 0em;
  color: #f8f8f8;
  max-width: 350px;
  margin: 20% 10% auto auto;
  padding-left: 1.5rem;
`;

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 25px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 576px) {
    padding: 0 15px 10px;
  }
`;

export const LoginForm = styled.form`
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 0) {
    max-width: 444px;
  }
`;

export const LoginFooter = styled.form`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

  @media (min-width: 0) {
    max-width: 444px;
  }
`;

export const LogoContainer = styled.div`
  height: 60px;
  margin-bottom: 3rem;
  width: 100%;
  margin-top: auto;
  display: flex;
  justify-content: center;
`;

export const FormControl = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  flex: 0 0 max-content;
`;

export const Label = styled.label`
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
  text-transform: capitaliza;
`;

export const Input = styled.input`
  font: normal 500 1.6rem/1.5 Work Sans, sans-serif;
  width: 100%;
  min-height: 46px;
  margin-top: 0.5rem;
  padding: 1rem;
  background: #f0f0f0;
  border: 0;
  box-sizing: border-box;
  border-radius: 8px;
  color: #4f5882cc;
  font-size: 14px;
  outline: none;
  border: thin solid transparent;
  transition: box-shadow 0.2s ease-in-out, border 0.3s ease-in-out;

  &:hover {
    border: thin solid #2f4cddbe;
  }

  &:focus {
    border: thin solid #2f4cddbe;
    box-shadow: 0 0 1px 2.5px #2f4cdd44;
  }

  &::placeholder {
    color: #afafaf;
  }
`;

export const Submit = styled.button`
  width: 100%;
  height: 50px;
  background: #2f4cdd;
  border-radius: 10px;
  border: thin solid transparent;
  font: normal 600 1.6rem/1.5 Work Sans, sans-serif;
  color: #f8f8f8;
  outline: none;
  cursor: pointer;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2f4cdddd;
  }

  &:focus {
    border: thin solid #2f4cddbe;
    box-shadow: 0 0 1px 2.5px #2f4cdd44;
  }

  &:disabled {
    background-color: #2f4cdddd;
    opacity: 0.8;
    cursor: wait;
  }
`;

export const Text = styled.p`
  font: normal 1.3rem/1.5 Work Sans, sans-serif;
  color: #4f5882;
`;

export const IconLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0 4px;
`;

export const Space = styled.div`
  height: 34px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
interface IMessage {
  type: String;
}
export const Message = styled.div`
  font: normal 1.2rem/1.5 Work Sans, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: ${(props: IMessage) => (props.type === 'sucess' ? '#E3FFE8' : '#FFE3E3')};
  color: ${(props: IMessage) => (props.type === 'sucess' ? '#066204' : '#960000')};
  transition: opacity 1s ease-in;
  animation: ${fadeIn} 0.5s linear;

  & > svg {
    margin-right: 0.5rem;
  }
`;
