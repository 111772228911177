import Axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';

import { IconLoading } from '../../../../components/IconLoading';
import { useTrackfyApi } from '../../../../hooks/useTrackfyApi';
import { useProductivityContext } from '../../context/PageProductivityContext';
import { usePeopleInAreaContext } from './context/PeopleInAreasContext';
import List from './List/List';

import './PeopleInAreasChart.css';
// import Axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
// import { fetchData } from '../../../../../services/requests';
// import cookieService from '../../../../../services/CookieService';

const PeopleInAreasChart: React.FC = () => {
  const { companySelected, professionSelected, initialDate, finalDate } = useProductivityContext();
  const { getPeopleInAreasChartDataApi } = useTrackfyApi();
  const { peopleInAreasChartData, setPeopleInAreasChartData, setPeopleValues, dividerPerFifteen } =
    usePeopleInAreaContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const token: any = cookieService.get('token');

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted)
      getPeopleInAreasChartDataApi(
        companySelected,
        professionSelected,
        initialDate,
        finalDate,
        currentRequest.token,
        setIsLoading,
        setPeopleInAreasChartData,
        dividerPerFifteen,
        setPeopleValues
      );

    return () => {
      mounted = false;
      currentRequest.cancel('PeopleInAreasChart request canceled by the user.');
    };
  }, [companySelected, professionSelected, initialDate, finalDate]);

  if (isLoading) return <IconLoading />;
  return <List monitoredData={peopleInAreasChartData} />;
};

export default PeopleInAreasChart;

/*
  const getPeopleInAreasChartData = async (
    cancelToken: CancelToken,
    projValue?: number,
    date?: any
  ) => {
    let projectValue = projValue;
    //let dateInitial = date;
    batchedUpdates(() => {
      setIsLoading(true);
      setPeopleValues([]);
    });

    if (projectSelctValue && projectSelctValue !== 0) projectValue = projectSelctValue;

    let compValue: number = 0;
    if (companiesSelctValue) compValue = companiesSelctValue;

    let funcValue: number = 0;
    if (professionsSelctValue) funcValue = professionsSelctValue;

    const initDate: number  = moment(initialDate).startOf('day').valueOf();
    const fimDate: number = moment(finalDate).valueOf();

    //dateInitial = moment(dateInitial).hours(0).minutes(0).seconds(0);

    return fetchData(
      token,
      `${baseUrl}/statistics/peopleInAreas`,
      {
        project: projectValue,
        company: compValue,
        profession: funcValue,
        initialDate: initDate,
        finalDate: fimDate,
      },
      cancelToken
    );
  };

   const getCollaboratorsQuantity = async (cancelToken: CancelToken) =>
    fetchData(
      token,
      `${baseUrl}/statistics/collaborators`,
      {
        project: projectSelctValue,
      },
      cancelToken
    );
  */

/*
   useEffect(() => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();
    let mounted: boolean = true;

    if (
      projectSelctValue &&
      typeof companiesSelctValue !== typeof undefined &&
      typeof professionsSelctValue !== typeof undefined
    ) {
      getPeopleInAreasChartData(currentRequest.token, projectSelctValue, initialDate)
        .then(async (response: AxiosResponse<any>) => {
          if (mounted) {
            if (!response.data) setPeopleInAreasChartData([]);
            else {
              await getCollaboratorsQuantity(currentRequest.token);

              const dividerPerFifteen = (base: PeopleInAreaDate[]): Array<PeopleInAreaDate[]> => {
                const divisor: number = 15;
                const newArray: Array<PeopleInAreaDate[]> = [];

                for (let i = 0; i < base.length; i += divisor) {
                  const divider: PeopleInAreaDate[] = base.slice(i, i + divisor);
                  newArray.push(divider);
                }
                return newArray;
              };

              batchedUpdates(() => {
                setPeopleValues(dividerPerFifteen(response.data));
                setPeopleInAreasChartData(dividerPerFifteen(response.data)[0]);
              });
            }
            setIsLoading(false);
          }
        })
        // eslint-disable-next-line no-console
        .catch((error: AxiosResponse<any>) => console.error(error));
    }

    return () => {
      currentRequest.cancel('Operation canceled by the user.');
      mounted = false;
    };
  }, [projectSelctValue,
      companiesSelctValue,
      professionsSelctValue,
      initialDate,
      finalDate
    ]);
    */
